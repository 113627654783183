import { log } from '@cambridgeassessment/aws-lambda-logging';
import { getHolds } from './getHoldStatus';
import { HoldUpdate } from '../types';
import { holdLearner } from './holdLearner';

export const removeOutdatedHolds = async (
  sessionId: number,
  qualificationId: number,
  centreId: string,
  learnerLists: Record<string, number[]>
): Promise<string> => {
  const holds = await getHolds(sessionId, qualificationId, centreId);

  const holdsToUpdate: HoldUpdate[] = [];
  holds.forEach((holdItem) => {
    const assessmentCode = holdItem.sortKey.split('-')[2];
    // Check if whole hold item can be removed i.e. whole subject has been withdrawn
    if (!learnerLists[assessmentCode]) {
      holdsToUpdate.push({ assessmentCode, hold: false });
      // Check if there are learners on hold to check
    } else if (
      holdItem.learnersOnHold &&
      holdItem.learnersOnHold.find((x) => learnerLists[assessmentCode].indexOf(x) === -1)
    ) {
      const activeHeldLearners = holdItem.learnersOnHold.filter(
        (x) => learnerLists[assessmentCode].indexOf(x) !== -1
      );
      if (activeHeldLearners.length)
        holdsToUpdate.push({
          assessmentCode,
          learnersOnHold: activeHeldLearners,
        });
      else
        holdsToUpdate.push({
          assessmentCode,
          hold: false,
        });
    }
  });
  if (holdsToUpdate.length) {
    log.info(`Removing out of date holds`);
    return holdLearner(qualificationId, sessionId, centreId, holdsToUpdate);
  }

  return 'No holds to update';
};

export default removeOutdatedHolds;
