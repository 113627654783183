import React from 'react';
import { useDebouncedCallback } from 'use-lodash-debounce';
import { StyledPagination, PaginationContainer, StyledTextField } from './Table.styles';

interface TablePaginationProps {
  count: number;
  page: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  'data-testid'?: string;
}

const TablePagination = ({
  count,
  page,
  handleChange,
  'data-testid': dataTestId,
}: TablePaginationProps): JSX.Element => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    handleChange(event, Number(event.target.value) || 1);
  };

  const debouncedCallback = useDebouncedCallback(handleInputChange, 300) as (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;

  return (
    <PaginationContainer data-testid={dataTestId}>
      <StyledPagination count={Math.ceil(count)} page={page} onChange={handleChange} />
      <span>Go to page:</span>
      <StyledTextField
        id="standard-number"
        type="number"
        onChange={debouncedCallback}
        inputProps={{ 'data-testid': 'pagination-go-to-input' }}
      />
    </PaginationContainer>
  );
};

TablePagination.defaultProps = {
  'data-testid': '',
};

export default TablePagination;
