import { useMemo } from 'react';
import { useAsyncTaskAxios } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';

export interface RemoveData {
  removeOutdatedHoldsResponse?: string;
  triggeringRemoveOutdatedHolds: boolean;
  errorTriggeringRemoveOutdatedHolds: Error | null;
  startRemoveOutdatedHolds: (learnerLists: Record<string, number[]>) => void;
}

export const useRemoveOutdatedHolds = (id: number, qid: number, centreId: string): RemoveData => {
  const config = useApplicationEnv();

  const removeOutdatedHoldsMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/centres/${centreId}/removeoutdatedholds`,
      method: 'POST',
    };
  }, [config]);

  const removeOutdatedHoldsTask = useAsyncTaskAxios<AxiosResponse<string>>(
    axios,
    removeOutdatedHoldsMemo
  );

  return {
    removeOutdatedHoldsResponse: removeOutdatedHoldsTask.result?.data,
    triggeringRemoveOutdatedHolds:
      removeOutdatedHoldsTask.started && removeOutdatedHoldsTask.pending,
    errorTriggeringRemoveOutdatedHolds: removeOutdatedHoldsTask.error,
    startRemoveOutdatedHolds: async (learnerLists: Record<string, number[]>) => {
      await removeOutdatedHoldsTask.start({ data: { learnerLists } });
    },
  };
};
