import React, { useMemo } from 'react';
import { lightFormat } from 'date-fns';
import WarningRoundedIcon from '@material-ui/icons/Warning';
import { Box } from '@material-ui/core';
import { ReportGeneratorStatus, UserInfo } from '@cambridgeassessment/checkpoint-dtos';
import { CheckpointTooltip } from '../WarningIcon/WarningIcon';

interface RunInfoLabelProps {
  status: ReportGeneratorStatus;
}

export const RunInfoLabel: React.FC<RunInfoLabelProps> = ({ status }) => {
  const userInfo: UserInfo = status.runBy;
  const formattedDate = lightFormat(new Date(status.runAt), 'dd-MM-yyyy HH:mm');
  const forLabel = useMemo(() => {
    if (status.input && status.input.centres && status.input.centres.length > 0) {
      if (status.input.centres.length === 1) {
        return status.input.centres[0];
      }
      return `${status.input.centres.length} centres`;
    }
    return 'All centres';
  }, [status.input]);
  return (
    <Box paddingTop="4px" display="flex" flexDirection="row" alignItems="center">
      {status.sfStatus === 'FAILED' && !status.errorCount && (
        <CheckpointTooltip
          title="Something went wrong. If this problem persists, please contact BTS helpdesk"
          arrow
        >
          <WarningRoundedIcon fontSize="small" htmlColor="#FC0303" />
        </CheckpointTooltip>
      )}
      {status.errorCount && (
        <CheckpointTooltip
          title={`${status.errorCount} reports have failed to generate. Please try again. If this problem persists, please contact BTS helpdesk`}
          arrow
        >
          <WarningRoundedIcon fontSize="small" htmlColor="#FCC652" />
        </CheckpointTooltip>
      )}
      <Box paddingLeft="4px">
        <b>last ran by:</b> {userInfo.name}
        <br />
        <b>on:</b> {formattedDate}
        <br />
        <b>for: </b>
        {forLabel}
        <br />
      </Box>
    </Box>
  );
};
