import { CentreStats } from '@cambridgeassessment/checkpoint-dtos';

export const sortGeneratedReports = (row: CentreStats): number => {
  if (row.latestGeneratedError) {
    return new Date(row.latestGeneratedError?.generatedRunInfo.runAt).getTime();
  }
  if (row.latestGenerated?.generatedRunInfo.runAt) {
    return new Date(row.latestGenerated?.generatedRunInfo.runAt).getTime();
  }
  return 0;
};
