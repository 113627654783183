import DynamoDB, { DocumentClient, ItemList, Key } from 'aws-sdk/clients/dynamodb';
import { flatten } from 'lodash';

export const getAllData = async (params: DocumentClient.QueryInput): Promise<ItemList> => {
  const dynamoDb: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({});
  const query = async (
    qParams: DocumentClient.QueryInput,
    startKey?: Key
  ): Promise<DocumentClient.QueryOutput> => {
    let q = { ...qParams };
    if (startKey) {
      q = { ...q, ExclusiveStartKey: startKey };
    }
    return dynamoDb.query(q).promise();
  };

  let lastEvaluatedKey: Key | undefined;
  const rows: ItemList[] = [];

  do {
    // eslint-disable-next-line no-await-in-loop
    const result = await query(params, lastEvaluatedKey);
    if (result.Items) rows.push(result.Items);
    lastEvaluatedKey = result.LastEvaluatedKey;
  } while (lastEvaluatedKey);

  return flatten(rows);
};

export default getAllData;
