import React, { useState, useMemo, useEffect, ChangeEvent } from 'react';
import { Link as RouterLink, useParams, useLocation, useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';
import { useDebounce } from 'use-lodash-debounce';
import WarningRoundedIcon from '@material-ui/icons/Warning';
import {
  Box,
  Container,
  Link,
  Typography,
  Tabs,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import { getQualification } from '@cambridgeassessment/checkpoint-utils';
import { Breadcrumbs, Button } from '@cambridgeassessment/cambridge-ui';
import { ExtendedFilter, LearnerMark } from '@cambridgeassessment/checkpoint-dtos';

// Utils
import { learnersOnHoldList } from '../utils/holdLearners';
import { contains } from '../utils/helpers';
import { sortLearnerMarks } from '../utils/sortLearnerMarks';
import { useFilters } from '../utils/useFilters';
import { useHoldStatus } from '../utils/useHoldStatus';
import { useLearnerStats } from '../utils/useLearnerStats';
import { useRemoveOutdatedHolds } from '../utils/useRemoveOutdatedHolds';
import { useSessions } from '../utils/useSessions';

// Components
import { CheckpointTooltip } from '../components/WarningIcon/WarningIcon';
import DialogWindow from '../components/DialogWindow/DialogWindow';
import { FilterTab } from '../components/Tabs/FilterTab';
import FilterCheckbox from '../components/FilterCheckbox/FilterCheckbox';
import { FilterTabLabel } from '../components/Tabs/FilterTabLabel';
import { LabelWithIndicator } from '../components/LabelWithIndicator';
import Loader from '../components/Loader/Loader';
import NoResultsView, { Error, NotFound } from '../components/NoResultsView/NoResultsView';
import { PageLoader } from '../components/PageLoader';
import SearchInput from '../components/SearchInput/SearchInput';
import Select from '../components/Select/Select';
import SessionExpired from '../components/SessionExpired/SessionExpired';
import Table from '../components/Table/Table';
import WarningMessage from '../components/WarningMessage/WarningMessage';

export interface SessionCentreQualPath {
  id: string;
  qid: string;
  cid: string;
}

export const CentreOverviewPage: React.FC = (): JSX.Element => {
  const { id, qid, cid } = useParams<SessionCentreQualPath>();
  const [subject, setSubject] = useState<string>();
  const [holdOrReleaseModal, setHoldOrReleaseModal] = useState(false);
  const [selectedLearner, setSelectedLearner] = useState<number>();
  const [selectedLearnerOnHold, isSelectedLearnerHeld] = useState(false);
  const [holdOrReleaseUpdates, setHoldOrReleaseUpdates] = useState<Record<string, string>>({});
  const [holdCompleteModal, setHoldCompleteModal] = useState(false);
  const [e2lFilterChecked, setE2lFilterChecked] = useState(false);
  const [refreshMarks, setRefreshMarks] = useState(true);
  const [firstPageLoad, setFirstPageLoad] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const { sessions, retrievingSessions, errorRetrievingSessions } = useSessions();

  const confirmHoldOrReleaseModal = (learnerId: number, hold: boolean): void => {
    setSelectedLearner(learnerId);
    isSelectedLearnerHeld(hold);
    setHoldOrReleaseModal(true);
  };

  const handleCloseHoldOrRelease = (): void => {
    setHoldOrReleaseUpdates({});
    setHoldOrReleaseModal(false);
  };

  const handleCloseHoldComplete = (): void => {
    setHoldOrReleaseUpdates({});
    setHoldCompleteModal(false);
    setRefreshMarks(true);
  };

  const { setHoldStatusResponse, errorSettingHoldStatus, setHoldStatus } = useHoldStatus(
    parseInt(id, 10),
    parseInt(qid, 10),
    cid,
    selectedLearner,
    holdOrReleaseModal
  );

  const currentSession = useMemo(
    () => sessions.find((session) => String(session.id) === id)?.displayName,
    [id, sessions]
  );

  const e2lAssessmentCode = useMemo(() => {
    const year = sessions.find((session) => String(session.id) === id)?.year;
    const qualification = getQualification(parseInt(qid, 10));
    // Report version 2 starts with year 2023 and onwards. Hence we must
    // return new e2l assessment code.
    return Number(year) <= 2022 ? qualification.oldE2L : qualification.e2l;
  }, [sessions, qid]);

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const debouncedQuery = useDebounce(searchQuery, 300);

  const handleSearch = (value: string | null): void => {
    setSearchQuery(value);
  };

  const {
    assessmentMarks,
    outdatedHolds,
    retrievingLearnerStats,
    errorRetrievingLearnerStats,
  } = useLearnerStats({
    sessionId: parseInt(id, 10),
    qualificationId: parseInt(qid, 10),
    centreId: cid,
    refreshMarks,
  });

  const {
    startRemoveOutdatedHolds,
    removeOutdatedHoldsResponse,
    triggeringRemoveOutdatedHolds,
    errorTriggeringRemoveOutdatedHolds,
  } = useRemoveOutdatedHolds(parseInt(id, 10), parseInt(qid, 10), cid);

  const { filter, filters, filterLearners } = useFilters(assessmentMarks);

  const subjects = useMemo(
    () =>
      orderBy(
        assessmentMarks.map(({ assessmentCode, assessmentName }) => ({
          value: assessmentCode,
          label: assessmentName,
        })),
        ['label'],
        ['asc']
      ),
    [assessmentMarks]
  );

  useEffect(() => {
    if (firstPageLoad && subjects && subjects.length) {
      setSubject(subjects[0].value);
      setFirstPageLoad(false);
    }
  }, [subjects]);

  useEffect(() => {
    if (assessmentMarks) {
      setRefreshMarks(false);
    }
  }, [assessmentMarks]);

  const handleSingleFilter = (): void => setE2lFilterChecked(!e2lFilterChecked);

  const confirmHoldCompleteModal = async (): Promise<void> => {
    setHoldOrReleaseModal(false);
    setHoldCompleteModal(true);
    setHoldStatus(
      Object.keys(holdOrReleaseUpdates).map((key: string) => {
        const learnerList =
          assessmentMarks.find((item) => item.assessmentCode === key)?.learnerMarks || [];
        const learnersOnHold = learnersOnHoldList(
          selectedLearner,
          !selectedLearnerOnHold,
          learnerList
        );
        return {
          assessmentCode: key,
          hold: learnersOnHold.length === 0 ? false : holdOrReleaseUpdates[key] === 'hold',
          learnersOnHold:
            learnersOnHold.length === 0 || learnersOnHold.length === learnerList.length
              ? undefined
              : learnersOnHold,
        };
      })
    );
  };

  const learnerLists = useMemo(
    () =>
      assessmentMarks
        .map((assessmentMark) => ({
          [assessmentMark.assessmentCode]: assessmentMark.learnerMarks.map(
            (learner) => learner.candidateNumber
          ),
        }))
        .reduce((prev, current) => ({ ...prev, ...current }), {}),
    [assessmentMarks]
  );

  const tableData = useMemo(() => {
    const subjectLearners =
      assessmentMarks.find((item) => item.assessmentCode === subject)?.learnerMarks || [];

    subjectLearners.sort((a, b) => a.candidateNumber - b.candidateNumber);
    return filterLearners(subjectLearners, filter).filter(
      (item) =>
        (!debouncedQuery ||
          contains(item.name, debouncedQuery) ||
          contains(String(item.candidateNumber), debouncedQuery)) &&
        (e2lFilterChecked && subject === e2lAssessmentCode
          ? item.teachingGroupNumber.startsWith('E')
          : true)
    );
  }, [assessmentMarks, subject, location, debouncedQuery, e2lFilterChecked]);

  const headerColumns = useMemo(
    () => [
      {
        header: 'Learner name',
        accessor: 'name',
        renderCell: (row: LearnerMark) =>
          row.hold === true ? (
            <LabelWithIndicator label={row.name} indicatorInMargin />
          ) : (
            `${row.name}`
          ),
      },
      {
        header: 'No.',
        accessor: 'candidateNumber',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Teaching Group',
        accessor: 'teachingGroupNumber',
        cellProps: {
          align: 'center' as const,
        },
      },
      ...(tableData && tableData.length > 0 ? Object.keys(tableData[0].marks) : []).map(
        (key: string) => ({
          header: `Component ${key}`,
          accessor: key,
          sortFunction: (row: LearnerMark): string => sortLearnerMarks(row, key),
          renderCell: (row: LearnerMark) => {
            if (row.marks[key] === null && row.itemMarks[key] !== null) {
              return (
                <CheckpointTooltip
                  title="No mark in EPS but mark in ESM, fix mark in EPS to resolve"
                  arrow
                >
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <WarningRoundedIcon fontSize="small" htmlColor="#FCC652" />
                    <Box pl={1}>
                      <b>{row.itemMarks[key]}</b>
                    </Box>
                  </Box>
                </CheckpointTooltip>
              );
            }
            if (
              row.marks[key] !== null &&
              row.itemMarks[key] !== null &&
              row.marks[key] !== row.itemMarks[key]
            ) {
              return (
                <CheckpointTooltip title="EPS mark differs from ESM Mark" arrow>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <b>{row.marks[key]}</b>
                    <Box px={1}>
                      <WarningRoundedIcon fontSize="small" htmlColor="#FC0303" />
                    </Box>
                    <b>{row.itemMarks[key]}</b>
                  </Box>
                </CheckpointTooltip>
              );
            }
            switch (row.marks[key]) {
              case 'A':
                return 'Absent';
              case 'M':
                return 'Missing';
              case null:
                return '';
              default:
                return row.marks[key] as string;
            }
          },
          cellProps: {
            align: 'center' as const,
          },
        })
      ),
      {
        header: 'Hold/Release',
        accessor: 'compliance',
        renderCell: (row: LearnerMark) => (
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={() => confirmHoldOrReleaseModal(row.candidateNumber, !!row.hold)}
          >
            {row.hold === true ? 'Release learner' : 'Hold learner'}
          </Button>
        ),
        cellProps: {
          align: 'center' as const,
        },
      },
    ],
    [tableData]
  );
  const retrievingPageDependencies = useMemo(() => retrievingSessions || retrievingLearnerStats, [
    retrievingSessions,
    retrievingLearnerStats,
  ]);
  // put in here any page deps where you want to error whole page,
  // if instead you want inline error somewhere in the page, handle that separately.
  const pageDependeciesError = useMemo(
    () => errorRetrievingLearnerStats || errorRetrievingSessions,
    [errorRetrievingSessions, errorRetrievingLearnerStats]
  );
  const notFound = useMemo(
    () =>
      id &&
      !retrievingSessions &&
      sessions.length > 0 &&
      sessions.filter((x) => x.id === parseInt(id, 10)).length === 0,
    [sessions, retrievingSessions, id]
  );
  const qual = useMemo(() => getQualification(parseInt(qid, 10)), [qid]);
  if (notFound || !qual) {
    return <NotFound />;
  }
  return (
    <>
      <SessionExpired
        errors={[errorRetrievingSessions, errorRetrievingLearnerStats, errorSettingHoldStatus]}
      />
      <Box py={1.5} borderBottom={`4px solid ${qual.color}`}>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center">
            <Box>
              <Typography variant="h4" gutterBottom>
                {cid}
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" gutter="sm">
                <Link component={RouterLink} to={`/session/${id}`}>
                  <span>{currentSession}</span>
                </Link>
                <Link component={RouterLink} to={`/session/${id}/qualification/${qid}`}>
                  <span>{qual.name}</span>
                </Link>
                <Typography>{cid}</Typography>
              </Breadcrumbs>
            </Box>
            {outdatedHolds && (
              <Box ml="auto" display="flex">
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    startRemoveOutdatedHolds(learnerLists);
                  }}
                  data-testid="buttonRemoveOutdatedHold"
                  disabled={triggeringRemoveOutdatedHolds || !!removeOutdatedHoldsResponse}
                >
                  {triggeringRemoveOutdatedHolds && <Loader />}
                  {!triggeringRemoveOutdatedHolds && 'Remove Outdated Holds'}
                </Button>
                {errorTriggeringRemoveOutdatedHolds && (
                  <WarningMessage message="Error updating outdated hold status" />
                )}
              </Box>
            )}
          </Box>
        </Container>
      </Box>
      <Box>
        {retrievingPageDependencies && <PageLoader />}
        {pageDependeciesError && <Error />}
        {assessmentMarks.length && (
          <Container maxWidth="lg">
            <Box mt="1rem" mb="2rem">
              <Tabs
                value={filter || 0}
                onChange={(
                  _event: React.ChangeEvent<Record<string, unknown>>,
                  newValue: ExtendedFilter
                ) => {
                  history.push(
                    newValue
                      ? `/session/${id}/qualification/${qid}/centre/${cid}?filter=${newValue}`
                      : `/session/${id}/qualification/${qid}/centre/${cid}`
                  );
                }}
                indicatorColor="primary"
                textColor="primary"
                data-testid="filter-tabs"
              >
                {filters.map((item) => (
                  <FilterTab
                    value={item.value}
                    label={
                      <FilterTabLabel
                        label={item.label}
                        primaryCount={item.count}
                        secondaryCount={item.entryCount}
                      />
                    }
                  />
                ))}
              </Tabs>
            </Box>
            <Box display="flex" my="1rem" alignItems="center">
              <Box pt="0.5rem" display="flex" alignItems="center">
                <Box mr="2rem">
                  <Select
                    label="Subject:"
                    options={subjects}
                    onChange={(e) => setSubject(String(e.target.value))}
                    value={subject}
                    data-testid="subject-select"
                  />
                </Box>
                {subject === e2lAssessmentCode && (
                  <Box mb={1}>
                    <FilterCheckbox
                      boxLabel="Filter learners by:"
                      singleFilterLabel="E prefix"
                      onChange={handleSingleFilter}
                      data-testid="single-filter"
                      checked={e2lFilterChecked}
                    />
                  </Box>
                )}
              </Box>
              <Box ml="auto">
                <SearchInput
                  value={searchQuery}
                  placeholder="Search for a learner name or number"
                  onChange={handleSearch}
                  onClear={() => handleSearch(null)}
                />
              </Box>
            </Box>
            {(debouncedQuery || e2lFilterChecked) && tableData?.length === 0 && (
              <NoResultsView
                title={`No results found for ‘${debouncedQuery || 'E prefix filter'}’`}
                message={
                  debouncedQuery
                    ? `We couldn’t find a match for ‘${debouncedQuery}’.
                  Please try another search.`
                    : ''
                }
                view="no-search"
              >
                {debouncedQuery ? (
                  <Button color="primary" size="small" onClick={() => handleSearch(null)}>
                    Reset the search
                  </Button>
                ) : (
                  <></>
                )}
              </NoResultsView>
            )}
            {!((debouncedQuery || e2lFilterChecked) && tableData?.length === 0) && (
              <>
                <Table<LearnerMark>
                  title={
                    assessmentMarks.find((item) => subject === item.assessmentCode)
                      ?.assessmentName || ''
                  }
                  getRowKey={(item: LearnerMark) => item.candidateNumber.toString()}
                  columns={headerColumns}
                  data={tableData}
                  rowsPerPage={10}
                  data-testid="learners-table"
                />
                {selectedLearner && subject && (
                  <DialogWindow
                    description={`Please confirm which subjects to ${
                      selectedLearnerOnHold ? 'release' : 'hold'
                    } for learner ${selectedLearner}:`}
                    onCloseDialog={handleCloseHoldOrRelease}
                    openDialog={holdOrReleaseModal}
                    title="Holds update"
                    ConfirmButtonOnClick={async () => {
                      await confirmHoldCompleteModal();
                    }}
                    data-testid="DialogConfirmHold"
                    disableButton={!Object.keys(holdOrReleaseUpdates).length}
                    buttonDataTestId="confirmHoldButton"
                  >
                    {selectedLearner && subject ? (
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="subject"
                          name="subject-hold"
                          onChange={(option: ChangeEvent<HTMLInputElement>) => {
                            const input: string[] = option.target.value.split('-');
                            const subjList =
                              input[0] === 'All'
                                ? assessmentMarks
                                    .map((x) => ({ [x.assessmentCode]: input[1] }))
                                    .reduce((res, item) => {
                                      const key = Object.keys(item)[0];
                                      res[key] = item[key];
                                      return res;
                                    })
                                : { [input[0]]: input[1] };
                            setHoldOrReleaseUpdates(subjList);
                          }}
                        >
                          <FormControlLabel
                            value={`${subject}-hold`}
                            control={<Radio color="primary" />}
                            data-testid="holdSubject"
                            label={
                              assessmentMarks.find((item) => subject === item.assessmentCode)
                                ?.assessmentName || ''
                            }
                          />
                          <FormControlLabel
                            value="All-hold"
                            control={<Radio color="primary" />}
                            label="All subjects"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <Loader />
                    )}
                  </DialogWindow>
                )}
                {selectedLearner && (
                  <DialogWindow
                    description={
                      setHoldStatusResponse
                        ? `Holds have been updated for learner ${selectedLearner}`
                        : `Updating holds for learner ${selectedLearner}...`
                    }
                    onCloseDialog={handleCloseHoldComplete}
                    openDialog={holdCompleteModal}
                    title="Holds update"
                    ConfirmButtonOnClick={() => {
                      handleCloseHoldComplete();
                    }}
                    data-testid="DialogConfirmHoldComplete"
                    disableButton={!setHoldStatusResponse}
                    buttonDataTestId="confirmHoldCompleteButton"
                  >
                    {setHoldStatusResponse ? <></> : <Loader />}
                  </DialogWindow>
                )}
              </>
            )}
          </Container>
        )}
      </Box>
    </>
  );
};
