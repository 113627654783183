import { useMemo } from 'react';
import { useAsyncTaskAxios } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';

export interface GenerateReportResponse {
  executionArn: string;
  startDate: string;
  executionName: string;
}

export interface GenerateData {
  generateReportResponse?: GenerateReportResponse;
  triggeringGenerateReport: boolean;
  errorTriggeringGenerateReport: Error | null;
  startGenerateReport: (centres: string[]) => void;
}

export const useGenerateReport = (id: string, qid: string, reportVersion: number): GenerateData => {
  const config = useApplicationEnv();

  const generateReportMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }

    const generateReportPath = reportVersion === 1 ? 'generateReport' : 'generateReportV2';

    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/${generateReportPath}`,
      method: 'POST',
    };
  }, [config, reportVersion]);

  const generateReportTask = useAsyncTaskAxios<AxiosResponse<GenerateReportResponse>>(
    axios,
    generateReportMemo
  );

  return {
    generateReportResponse: generateReportTask.result?.data,
    triggeringGenerateReport: generateReportTask.started && generateReportTask.pending,
    errorTriggeringGenerateReport: config
      ? generateReportTask.error
      : new Error('Application env is undefined.'),
    startGenerateReport: async (centres: string[]) => {
      if (centres.length > 0) await generateReportTask.start({ data: { centres } });
      else await generateReportTask.start();
    },
  };
};
