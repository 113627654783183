import { mapValues, keyBy, flatMap, groupBy, mean, meanBy, orderBy } from 'lodash';
import { AssessmentResult, GroupResult, LearnerResult } from '@cambridgeassessment/checkpoint-dtos';
import { groupByAge } from './aggregates';

const getAverageV2 = (data: number[]): number => {
  const average = Math.round(mean(data));

  if (average < 0) return 0;
  if (average > 50) return 50;
  return average;
};

const getAverageScoreV2 = (learnerResults: LearnerResult[]): number =>
  getAverageV2(learnerResults.map((item) => item.score));

const getStrandScoreAverageV2 = (
  learnerResults: LearnerResult[]
): { strand: string; score: number }[] => {
  const flatStrands = flatMap(learnerResults, (x) =>
    x.strandScores?.sort((a, b) => a?.order - b?.order)
  );
  const grouped = groupBy(flatStrands, (x) => x?.strand);
  return Object.keys(grouped).map((key) => ({
    strand: key,
    score: Math.round(meanBy(grouped[key], (x) => x?.score)),
  }));
};

const getAverageByTeachingGroupV2 = (assessmentResult: AssessmentResult): GroupResult[] => {
  const centreAverage = getAverageScoreV2(assessmentResult.learnerResults);
  const centreStrandAverages = getStrandScoreAverageV2(assessmentResult.learnerResults);
  const grouped = groupBy(assessmentResult.learnerResults, 'teachingGroupNumber');
  const groupedAverage = mapValues(grouped, (y) => getAverageV2(y.map((x) => x.score)));
  return orderBy(
    Object.keys(grouped).map((key) => ({
      score: groupedAverage[key],
      teachingGroupNumber: key,
      teachingGroupName: grouped[key][0].teachingGroupName,
      strandAverages: getStrandScoreAverageV2(grouped[key]).map((strand) => ({
        ...strand,
        centreAverage: centreStrandAverages.filter(
          (centreStrandAverage) => centreStrandAverage.strand === strand.strand
        )[0].score,
      })),
      numberOfLearners: grouped[key].length,
      learnerResults: orderBy(grouped[key], 'name', 'asc'),
      centreAverage,
    })),
    'teachingGroupName',
    'asc'
  );
};

const getStrandAverageForEachSubjectV2 = (
  assessmentResults: AssessmentResult[]
): { [key: string]: { strand: string; score: number }[] } => {
  const groupedLearners: { [key: string]: AssessmentResult } = keyBy(
    assessmentResults,
    (x) => x.assessmentCode
  );
  return mapValues(groupedLearners, (y) => getStrandScoreAverageV2(y.learnerResults));
};

const getGroupedAveragesV2 = (assessmentResults: AssessmentResult[]): { [key: string]: number } =>
  mapValues(
    keyBy(assessmentResults, (x) => x.assessmentCode),
    (y) => getAverageV2(y.learnerResults.map((x) => x.score))
  );

const groupLearnersByScoreV2 = (
  learnerResult: LearnerResult[],
  strand?: string
): { [key: string]: LearnerResult[] } =>
  learnerResult.reduce((accumulator, current) => {
    const strandScore = current.strandScores?.find(
      (currentScore) => currentScore.strand === strand
    );

    if (strand && !strandScore?.score) {
      return accumulator;
    }

    const rawScore = strand && strandScore?.score ? strandScore.score : current.score;
    const score = Math.round(rawScore);

    // If score value varies from 0 to 40
    for (let iterator = 0; iterator < 5; iterator += 1) {
      if (score <= iterator * 10) {
        return {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          ...accumulator,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [iterator]: accumulator[iterator] ? [...accumulator[iterator], current] : [current],
        };
      }
    }
    // If score 40+
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return { ...accumulator, 5: accumulator[5] ? [...accumulator[5], current] : [current] };
  }, {});

const groupAgesByScoreV2 = (
  learnerResult: LearnerResult[],
  andUnder: number,
  mainGroup: number,
  andOver: number,
  strand?: string
): {
  [key: string]: {
    [key: string]: LearnerResult[];
  };
} => {
  const groupedLearners = groupLearnersByScoreV2(learnerResult, strand);

  return Object.keys(groupedLearners).reduce(
    (accumulator, current) => ({
      ...accumulator,
      [current]: groupByAge(groupedLearners[current], andUnder, mainGroup, andOver),
    }),
    {}
  );
};

export {
  getAverageV2,
  getAverageByTeachingGroupV2,
  getAverageScoreV2,
  getGroupedAveragesV2,
  getStrandAverageForEachSubjectV2,
  getStrandScoreAverageV2,
  groupAgesByScoreV2,
  groupLearnersByScoreV2,
};
