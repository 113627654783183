import React, { useMemo } from 'react';
import { Button } from '@cambridgeassessment/cambridge-ui';
import { useAuth } from 'oidc-react';
import { Dialog, DialogContent } from '@material-ui/core';
import NoResultsView from '../NoResultsView/NoResultsView';
import { isAxiosError, isForbidden } from '../../utils/axios-helpers';

export interface SessionExpiredProps {
  errors: (Error | null)[];
}

const SessionExpiredView: React.FC<SessionExpiredProps> = ({ errors }): JSX.Element => {
  const auth = useAuth();
  const sessionExpired = useMemo(
    () => errors.some((x) => x && isAxiosError(x) && isForbidden(x.response)),
    [errors]
  );

  return (
    <Dialog open={sessionExpired}>
      <DialogContent>
        <NoResultsView
          title="Session Expired"
          message="Please login again to continue using this application"
          view="not-authorized"
          data-testid="session-expired"
        >
          <Button
            onClick={() =>
              auth?.signIn({
                data: { targetUrl: window.location.pathname + window.location.search },
              })
            }
          >
            Login
          </Button>
        </NoResultsView>
      </DialogContent>
    </Dialog>
  );
};

export default SessionExpiredView;
