// eslint-disable-next-line max-classes-per-file
export class NotFoundError extends Error {
  public constructor(message?: string) {
    super(message);

    Object.defineProperty(this, 'name', {
      value: 'NotFoundError',
      enumerable: false,
      configurable: true,
    });
    Object.setPrototypeOf(this, NotFoundError.prototype);

    Error.captureStackTrace(this, NotFoundError);
  }
}

export class InvalidInputError extends Error {
  public constructor(message?: string) {
    super(message);

    Object.defineProperty(this, 'name', {
      value: 'InvalidInputError',
      enumerable: false,
      configurable: true,
    });

    Object.setPrototypeOf(this, InvalidInputError.prototype);

    Error.captureStackTrace(this, InvalidInputError);
  }
}

export class APITimeoutError extends Error {
  public constructor(message?: string) {
    super(message);

    Object.defineProperty(this, 'name', {
      value: 'APITimeoutError',
      enumerable: false,
      configurable: true,
    });

    Object.setPrototypeOf(this, APITimeoutError.prototype);
    Error.captureStackTrace(this, APITimeoutError);
  }
}

export class InconsistentDataError extends Error {
  public constructor(message?: string) {
    super(message);

    Object.defineProperty(this, 'name', {
      value: 'InconsistentDataError',
      enumerable: false,
      configurable: true,
    });
    Object.setPrototypeOf(this, InconsistentDataError.prototype);

    Error.captureStackTrace(this, InconsistentDataError);
  }
}
