import React, { useState, useEffect, useMemo } from 'react';
import { orderBy } from 'lodash';
import {
  Table as MaterialTable,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Typography,
  TableCellProps,
  TableSortLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from './TablePagination';
import { Container, HeaderCell, BodyCell } from './Table.styles';

type Order = undefined | 'asc' | 'desc';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RowBase = Record<string, any>;
interface TableProps<RowType extends RowBase> {
  title: string;
  rowsPerPage: number;
  getRowKey: (item: RowType) => string;
  columns: {
    header: string;
    accessor: string;
    sortFunction?: (row: RowType) => string | number;
    renderCell?: (row: RowType) => JSX.Element | string;
    cellProps?: TableCellProps;
  }[];
  data?: RowType[];
  'data-testid'?: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const sortOrder = (currentOrderAsc: boolean): 'asc' | 'desc' => {
  if (currentOrderAsc) return 'desc';
  return 'asc';
};

const Table = <RowType extends RowBase>({
  title,
  columns,
  getRowKey,
  data,
  rowsPerPage,
  'data-testid': dataTestId,
}: TableProps<RowType>): JSX.Element => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [sortOptions, setSort] = useState<{
    column: string | ((item: RowType) => string | number);
    order: Order;
  }>();

  const tableData = useMemo<RowType[]>((): RowType[] => {
    if (sortOptions && sortOptions.column) {
      const orderOptions = sortOptions.order ? sortOptions.order : false;
      return orderBy(data, [sortOptions.column], [orderOptions]);
    }
    return data || [];
  }, [data, sortOptions]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    if (tableData && value <= Math.ceil(tableData.length / rowsPerPage)) {
      setPage(value);
    }
    if (tableData && value > Math.ceil(tableData.length / rowsPerPage)) {
      setPage(Math.ceil(tableData.length / rowsPerPage));
    }
  };

  const currentPage = useMemo(
    () => tableData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage),
    [tableData, page, rowsPerPage]
  );

  useEffect(() => {
    setPage(1);
  }, [tableData]);

  const handleSort = (
    event: React.MouseEvent<unknown>,
    column: string | ((item: RowType) => string | number)
  ): void =>
    setSort({
      column,
      order: sortOrder(
        !!sortOptions && sortOptions.column === column && sortOptions.order === 'asc'
      ),
    });

  return (
    <TableContainer component={Container}>
      <Typography variant="h4">{title}</Typography>
      <MaterialTable className={classes.table} aria-label="table" data-testid={dataTestId}>
        <TableHead>
          <TableRow>
            {columns.map(({ header, accessor, cellProps, sortFunction }) => (
              <HeaderCell
                key={header}
                {...cellProps}
                onClick={(e) => handleSort(e, sortFunction || accessor)}
              >
                <TableSortLabel
                  active={sortOptions?.column === accessor}
                  direction={sortOptions?.order}
                >
                  {header}
                </TableSortLabel>
              </HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData &&
            currentPage.map((row: RowType) => (
              <TableRow key={getRowKey(row)}>
                {columns.map(({ header, accessor, cellProps, renderCell }) => (
                  <BodyCell key={`${getRowKey(row)}-${header}`} {...cellProps}>
                    {renderCell ? renderCell(row) : row[accessor]}
                  </BodyCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </MaterialTable>
      {tableData && !!tableData.length && (
        <TablePagination
          count={tableData.length / rowsPerPage}
          page={page}
          handleChange={handleChange}
        />
      )}
    </TableContainer>
  );
};

Table.defaultProps = {
  data: [],
  'data-testid': '',
};

export default Table;
