import { useMemo } from 'react';
import { useAsyncTaskAxios } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { ProcessType } from '@cambridgeassessment/checkpoint-dtos';

export interface IssueReportResponse {
  executionArn: string;
  startDate: string;
}

export interface IssueData {
  issueReportResponse?: IssueReportResponse;
  triggeringIssueReport: boolean;
  errorTriggeringIssueReport: Error | null;
  startIssueReport: (centreId: string, force: boolean) => void;
}

export const useIssueReport = (
  id: string,
  qid: string,
  processType: ProcessType,
  reportVersion: number
): IssueData => {
  const config = useApplicationEnv();

  const issueReportMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }

    const issueReportQuery = reportVersion === 1 ? '?legacye2l=true' : '';

    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/batch/${processType}${issueReportQuery}`,
      method: 'POST',
    };
  }, [config, reportVersion]);

  const issueReportTask = useAsyncTaskAxios<
    AxiosResponse<{ executionArn: string; startDate: string }>
  >(axios, issueReportMemo);

  return {
    issueReportResponse: issueReportTask.result?.data,
    triggeringIssueReport: issueReportTask.started && issueReportTask.pending,
    errorTriggeringIssueReport: issueReportTask.error,
    startIssueReport: async (centreId: string, force: boolean) => {
      if (centreId) await issueReportTask.start({ data: { centreId, force } });
      else await issueReportTask.start({ data: { force } });
    },
  };
};
