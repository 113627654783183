import { log } from '@cambridgeassessment/aws-lambda-logging';
import { keyBy } from 'lodash';
import {
  AssessmentComponents,
  CentreHoldStatus,
  HoldStatusDb,
} from '@cambridgeassessment/checkpoint-dtos';
import { DynamoDB } from 'aws-sdk';
import axios from 'axios';
import { NotFoundError } from '../middlewares/customErrors';

export const getHolds = async (
  sessionId: number,
  qualificationId: number,
  centreId: string
): Promise<HoldStatusDb[]> => {
  const dynamoDb: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({});

  log.info(`Getting hold status`);
  const DYNAMOTABLE = process.env.DYNAMODBTABLE || '';
  const response = await dynamoDb
    .query({
      TableName: DYNAMOTABLE,
      KeyConditionExpression: 'partitionKey = :partitionKey and begins_with(sortKey, :sortKey)',
      ExpressionAttributeValues: {
        ':partitionKey': `${sessionId}-${qualificationId}`,
        ':sortKey': `HOLD-${centreId}`,
      },
    })
    .promise();
  log.debug(`Dynamo query response ${JSON.stringify(response)}`);
  return response.Items as HoldStatusDb[];
};

export const getHoldStatus = async (
  qualificationId: number,
  sessionId: number,
  centreId: string,
  includeOutdated?: boolean
): Promise<CentreHoldStatus[]> => {
  const holds = await getHolds(sessionId, qualificationId, centreId);

  const assessmentfromDb = keyBy(holds, (item: HoldStatusDb) => {
    return item.sortKey.split('-')[2];
  });
  const XAPI_DOMAIN = process.env.CXAPI_DOMAIN || '';

  const url = `${XAPI_DOMAIN}/v1/sessions/${sessionId}/qualifications/${qualificationId}/assessmentcomponents?centreId=${centreId}`;
  const results = await axios.get<AssessmentComponents[]>(url);
  if (results.data.length === 0) {
    throw new NotFoundError(
      `No items were found for sessionId ${sessionId} and qualificationId ${qualificationId} for centre ${centreId}`
    );
  }
  const currentHolds = results.data.map((assessment) => {
    const assessmentObject = assessmentfromDb[`${assessment.assessmentCode}`];

    return {
      assessmentCode: assessment.assessmentCode,
      assessmentName: assessment.name,
      isWholeSubjectOnHold: !!(assessmentObject && !assessmentObject.learnersOnHold),
      heldLearners:
        assessmentObject && assessmentObject.learnersOnHold ? assessmentObject.learnersOnHold : [],
    };
  });
  if (includeOutdated) {
    const outdatedSubjects = holds.filter(
      (holdItem) =>
        !results.data.find(
          (assessment) => assessment.assessmentCode === holdItem.sortKey.split('-')[2]
        )
    );
    if (outdatedSubjects.length) {
      return [
        ...currentHolds,
        ...outdatedSubjects.map((holdItem) => ({
          assessmentCode: holdItem.sortKey.split('-')[2],
          assessmentName: '',
          isWholeSubjectOnHold: !holdItem.learnersOnHold,
          heldLearners: holdItem.learnersOnHold ? holdItem.learnersOnHold : [],
        })),
      ];
    }
  }
  return currentHolds;
};

export default getHoldStatus;
