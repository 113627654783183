import { useMemo } from 'react';
import { CentreHoldStatus } from '@cambridgeassessment/checkpoint-dtos';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';

interface AssessmentHold {
  assessmentCode: string;
  hold?: boolean;
  learnersOnHold?: number[];
}

export interface HoldData {
  holdStatus?: CentreHoldStatus[];
  retrievingHoldStatus: boolean;
  errorRetrievingHoldStatus: Error | null;
  retrievingSetHoldStatusResponse: boolean;
  errorSettingHoldStatus: Error | null;
  setHoldStatus: (assessmentHold: AssessmentHold[]) => void;
  setHoldStatusResponse?: string;
}

export const useHoldStatus = (
  sessionId: number,
  qualificationId: number,
  centreId?: string,
  selected?: string | number,
  holdOrReleaseModalOpen?: boolean
): HoldData => {
  const config = useApplicationEnv();

  const getHoldStatus = useMemo(() => {
    if (!config || !selected || !holdOrReleaseModalOpen || !centreId) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}/centres/${centreId}/getholdstatus`,
    };
  }, [config, selected, holdOrReleaseModalOpen, centreId]);

  const getHoldStatusTask = useAsyncTaskAxios<AxiosResponse<CentreHoldStatus[]>>(
    axios,
    getHoldStatus
  );

  const setHoldMemo = useMemo(() => {
    if (!config || !selected || !centreId) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}/centres/${centreId}/holdlearner`,
      method: 'POST',
    };
  }, [config, centreId, selected]);

  const setHoldTask = useAsyncTaskAxios<AxiosResponse<string>>(axios, setHoldMemo);

  useAsyncRun(getHoldStatusTask);

  return {
    holdStatus: getHoldStatusTask.result?.data,
    retrievingHoldStatus:
      (!getHoldStatusTask.error && !getHoldStatusTask.result) ||
      (getHoldStatusTask.started && getHoldStatusTask.pending),
    errorRetrievingHoldStatus: holdOrReleaseModalOpen ? getHoldStatusTask.error : null,
    retrievingSetHoldStatusResponse:
      (!setHoldTask.error && !setHoldTask.result) || (setHoldTask.started && setHoldTask.pending),
    errorSettingHoldStatus: setHoldTask.error,
    setHoldStatus: async (assessmentHold: AssessmentHold[]) => {
      await setHoldTask.start({ data: { assessmentHold } });
    },
    setHoldStatusResponse: setHoldTask.result?.data,
  };
};
