import { LearnerMark } from '@cambridgeassessment/checkpoint-dtos';

const padLength = 3;
export const sortLearnerMarks = (row: LearnerMark, key: string): string => {
  if (row.marks[key] === null && row.itemMarks[key] !== null) {
    return String(row.itemMarks[key]).padStart(padLength, '0');
  }
  if (
    row.marks[key] !== null &&
    row.itemMarks[key] !== null &&
    row.marks[key] !== row.itemMarks[key]
  ) {
    return String(row.marks[key]).padStart(padLength, '0');
  }
  switch (row.marks[key]) {
    case 'A':
      return 'Absent';
    case 'M':
      return 'Missing';
    case null:
      return '';
    default:
      return String(row.marks[key]).padStart(padLength, '0');
  }
};
