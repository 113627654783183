import React, { useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Paper, IconButton, InputBase, Divider } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

interface SearchInputProps {
  placeholder: string;
  onChange: (value: string | null) => void;
  value: string | null;
  onClear: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '0 0 0 18px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginBottom: 0,
      border: 'none',
      flex: 1,
      '& > input': {
        padding: '10px 12px',
        '&::placeholder': {
          opacity: 1,
        },
      },
    },
    divider: {
      height: 20,
    },
  })
);

const SearchInput = ({ placeholder, onChange, value, onClear }: SearchInputProps): JSX.Element => {
  const classes = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <Paper className={classes.root}>
      <SearchIcon />
      <InputBase
        value={value || ''}
        onChange={handleChange}
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': placeholder }}
      />
      {value !== null && value.length > 0 && (
        <>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton aria-label="clear-input" onClick={onClear} data-testid="search-clear-button">
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      )}
    </Paper>
  );
};

export default SearchInput;
