import { Filter, CentreStats, CentreStatsSummaryProps } from '@cambridgeassessment/checkpoint-dtos';
import { mapValues, sumBy } from 'lodash';

export const filterPredicates: {
  [key in Exclude<Filter, 'withUpdates'>]: (data: CentreStats) => boolean;
} = {
  complete: (x: CentreStats) => x.completeCount === x.entryCount,
  incomplete: (x: CentreStats) => x.incompleteCount > 0,
  missing: (x: CentreStats) => x.missingCount > 0,
  absent: (x: CentreStats) => x.absentCount > 0,
  held: (x: CentreStats) => !!x.hasHeldLearners,
  inError: (x: CentreStats) => x.latestGeneratedError !== undefined,
  outdated: (x: CentreStats) =>
    x.latestIssued !== undefined &&
    x.latestIssued.generatedRunInfo !== undefined &&
    x.latestIssued?.generatedRunInfo?.executionId !==
      x.latestGenerated?.generatedRunInfo.executionId,
};

export const filterPredicatesWithUpdates: { [key in Filter]: (data: CentreStats) => boolean } = {
  ...filterPredicates,
  withUpdates: (x: CentreStats) =>
    x.dataUpdates?.totalCount !== undefined && x.dataUpdates.totalCount > 0,
};

export const filterFields: {
  [key in Exclude<Filter, 'withUpdates'>]: (data: CentreStats) => number;
} = {
  complete: (x: CentreStats) => x.completeCount,
  incomplete: (x: CentreStats) => x.incompleteCount,
  missing: (x: CentreStats) => x.missingCount,
  absent: (x: CentreStats) => x.absentCount,
  held: (x: CentreStats) => (x.hasHeldLearners ? 1 : 0),
  inError: (x: CentreStats) => (x.latestGeneratedError ? x.entryCount : 0),
  outdated: (x: CentreStats) =>
    x.latestIssued &&
    x.latestIssued.generatedRunInfo &&
    x.latestIssued?.generatedRunInfo?.executionId !==
      x.latestGenerated?.generatedRunInfo.executionId
      ? x.entryCount
      : 0,
};

export const filterFieldsWithUpdates: { [key in Filter]: (data: CentreStats) => number } = {
  ...filterFields,
  withUpdates: (x: CentreStats) => (x.dataUpdates?.totalCount ? x.dataUpdates.totalCount : 0),
};

export const centreStatsFilter = (
  predicates: { [key in keyof CentreStatsSummaryProps]: (data: CentreStats) => boolean },
  fields: { [key in keyof CentreStatsSummaryProps]: (data: CentreStats) => number },
  centreStats: CentreStats[]
): CentreStatsSummaryProps | null => {
  if (centreStats.length === 0) {
    return null;
  }
  const mapped = mapValues(predicates, (value, key) => {
    const field = fields[key as Filter];
    if (!value || !field) return undefined;

    return {
      centreCount: centreStats.filter(value).length,
      entryCount: key === 'held' ? null : sumBy(centreStats.filter(value), (z) => field(z)),
    };
  });

  return mapped as CentreStatsSummaryProps;
};

export const groupCentreStatsWithUpdates = (
  centreStats: CentreStats[]
): CentreStatsSummaryProps | null =>
  centreStatsFilter(filterPredicatesWithUpdates, filterFieldsWithUpdates, centreStats);

export const groupCentreStats = (centreStats: CentreStats[]): CentreStatsSummaryProps | null =>
  centreStatsFilter(filterPredicates, filterFields, centreStats);
