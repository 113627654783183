import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@cambridgeassessment/cambridge-ui';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';
import { ReactComponent as NoResultsIcon } from '../../icons/noResults.svg';
import { ReactComponent as NotFoundIcon } from '../../icons/notFound.svg';
import { ReactComponent as NoSearchResultsIcon } from '../../icons/noSearchResults.svg';
import { ReactComponent as NotAuthorizedIcon } from '../../icons/notAuthorized.svg';

export interface NoResultsViewProps {
  title: string;
  message: string;
  children?: JSX.Element;
  view: 'error' | 'no-search' | 'no-results' | 'not-found' | 'not-authorized';
  'data-testid'?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    message: {
      whiteSpace: 'pre-line',
      textAlign: 'center',
    },
  })
);

const views = {
  error: <ErrorIcon title="Error" />,
  'no-search': <NoSearchResultsIcon title="No search results" />,
  'no-results': <NoResultsIcon title="No results" />,
  'not-found': <NotFoundIcon title="Not found" />,
  'not-authorized': <NotAuthorizedIcon title="Not Authorized" />,
};

const NoResultsView: React.FC<NoResultsViewProps> = ({
  title,
  message,
  children,
  view,
  'data-testid': dataTestId,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Box
      mt="3rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      data-testid={dataTestId}
    >
      {views[view]}
      <Box my="3rem">
        <Typography variant="h3" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography className={classes.message} variant="body1" align="center" gutterBottom>
          {message}
        </Typography>
        <Box mt="2rem" display="flex" justifyContent="center">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

interface ErrorProps {
  title?: string;
  message?: string;
  'data-testid'?: string;
}

export const Error: React.FC<ErrorProps> = ({
  title = 'Something went wrong',
  message = 'We are working on fixing the problem. Please try again or come back later',
  'data-testid': dataTestId = 'error-view',
}): JSX.Element => (
  <NoResultsView title={title} message={message} view="error" data-testid={dataTestId}>
    <Button href="/">Go to home page</Button>
  </NoResultsView>
);

export const NotFound: React.FC<ErrorProps> = ({
  title = 'Page not found...',
  message = 'Sorry we are unable to find the page you are looking for. The page is missing or you assembled the link incorrectly.',
  'data-testid': dataTestId = 'not-found-view',
}): JSX.Element => (
  <NoResultsView title={title} message={message} view="not-found" data-testid={dataTestId}>
    <Button href="/">Go to home page</Button>
  </NoResultsView>
);

export default NoResultsView;
