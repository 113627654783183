import { useMemo } from 'react';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { SeriesStats } from '@cambridgeassessment/checkpoint-dtos';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';

export interface SessionStatsInfo {
  retrievingSessionStats: boolean;
  errorRetrievingSessionStats: Error | null;
  sessionStats: SeriesStats[];
}

export const useSessionStats = (id: string): SessionStatsInfo => {
  const config = useApplicationEnv();
  const getSeriesStatsMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/stats`,
    };
  }, [config, id]);

  const getSeriesStatsTask = useAsyncTaskAxios<AxiosResponse<SeriesStats[]>>(
    axios,
    getSeriesStatsMemo
  );
  useAsyncRun(config && id && getSeriesStatsTask);

  return {
    retrievingSessionStats:
      (getSeriesStatsTask.started && getSeriesStatsTask.pending) ||
      (!getSeriesStatsTask.result && !getSeriesStatsTask.error),
    errorRetrievingSessionStats: config
      ? getSeriesStatsTask.error
      : new Error('Application env is undefined.'),
    sessionStats: getSeriesStatsTask.result?.data || [],
  };
};
