import { useMemo } from 'react';
import { useAsyncTaskAxios } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';

export interface GenerateDownloadData {
  generateDownloadReportResponse?: string;
  triggeringGenerateDownloadReport: boolean;
  errorTriggeringGenerateDownloadReport: Error | null;
  startGenerateDownloadReport: () => void;
}

export const useGenerateDownloadReport = (id: string, qid: string): GenerateDownloadData => {
  const config = useApplicationEnv();

  const generateDownloadReportMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions/${id}/qualifications/${qid}/generateReportQueue`,
      method: 'POST',
    };
  }, [config]);

  const generateDownloadReportTask = useAsyncTaskAxios<AxiosResponse<string>>(
    axios,
    generateDownloadReportMemo
  );

  return {
    generateDownloadReportResponse: generateDownloadReportTask.result?.data,
    triggeringGenerateDownloadReport: generateDownloadReportTask.started,
    errorTriggeringGenerateDownloadReport: config
      ? generateDownloadReportTask.error
      : new Error('Application env is undefined.'),
    startGenerateDownloadReport: async () => generateDownloadReportTask.start(),
  };
};
