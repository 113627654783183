import React from 'react';
import { Box, Typography } from '@material-ui/core';

export interface FilterTabLabelProps {
  label: string;
  primaryCount: number;
  secondaryCount?: number | null;
  secondaryCountType?: 'entries' | 'updates';
}

export const FilterTabLabel: React.FC<FilterTabLabelProps> = ({
  label,
  primaryCount,
  secondaryCount,
  secondaryCountType,
}): JSX.Element => (
  <>
    <Box>
      <Typography color="inherit" variant="h3">
        {primaryCount.toLocaleString()}
      </Typography>
    </Box>
    <Box height="20px">
      {secondaryCount && (
        <Typography color="inherit" variant="caption" className="semi-bold">
          {`${secondaryCount.toLocaleString()} ${secondaryCountType || 'entries'}`}
        </Typography>
      )}
    </Box>
    <Box mb="1rem">
      <Typography color="inherit" variant="subtitle2">
        {label}
      </Typography>
    </Box>
  </>
);
