import { useMemo } from 'react';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import { sumBy } from 'lodash';
import { CentreStats, Filter, CentreStatsSummaryProps } from '@cambridgeassessment/checkpoint-dtos';
import {
  groupCentreStatsWithUpdates,
  filterPredicatesWithUpdates,
} from '@cambridgeassessment/checkpoint-utils';
// INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)
//
// import { useQualificationsUpdates } from './useQualificationsUpdates';

export interface CentreStatsProps {
  sessionId: number;
  qualificationId: number;
  filter?: Filter;
}

export interface CentreStatsData {
  retrievingCentreStats: boolean;
  errorRetrievingCentreStats: Error | null;
  filtered?: CentreStats[];
  totalCount?: number;
  totalEntryCount?: number;
  centreSummaryStats?: CentreStatsSummaryProps | null;
}

export const useCentreStats = (
  seshQualProps: CentreStatsProps,
  reportVersion: number
): CentreStatsData => {
  const config = useApplicationEnv();

  const { sessionId, qualificationId, filter } = seshQualProps;

  const getCentreStatsMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }

    const getCentreStatsQuery = reportVersion === 1 ? '?legacye2l=true' : '';

    return {
      url: `${config.API_DOMAIN}/sessions/${sessionId}/qualifications/${qualificationId}${getCentreStatsQuery}`,
    };
  }, [config, reportVersion]);

  const getCentreStatsTask = useAsyncTaskAxios<AxiosResponse<CentreStats[]>>(
    axios,
    getCentreStatsMemo
  );
  useAsyncRun(config && getCentreStatsTask);

  // INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)
  //
  // const {
  //   sessionUpdates,
  //   retrievingSessionUpdates,
  //   errorRetrievingSessionUpdates,
  // } = useQualificationsUpdates(sessionId, [qualificationId]);

  const centreStats = useMemo(
    () =>
      getCentreStatsTask.result?.data.map(
        (centre) =>
          // INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)
          //
          // const centreWithUpdates =
          //   sessionUpdates &&
          //   sessionUpdates[qualificationId]?.centres.find((c) => c.centreId === centre.id);

          // if (centreWithUpdates) {
          //   return { ...centre, dataUpdates: centreWithUpdates };
          // }
          centre
      ),
    [getCentreStatsTask, qualificationId /* , sessionUpdates */]
  );

  const filtered = useMemo(() => {
    if (!centreStats) {
      return undefined;
    }
    if (!filter) {
      return centreStats;
    }
    const pred = filterPredicatesWithUpdates[filter];

    return centreStats.filter(pred);
  }, [centreStats, filter]);

  const centreSummaryStats = useMemo(() => {
    if (!centreStats) {
      return undefined;
    }
    return groupCentreStatsWithUpdates(centreStats);
  }, [centreStats, filter]);

  // INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)
  // Below are commented out parts that relate to with updates feature
  return {
    retrievingCentreStats: getCentreStatsTask.started && getCentreStatsTask.pending, // (getCentreStatsTask.started && getCentreStatsTask.pending) || retrievingSessionUpdates,
    errorRetrievingCentreStats:
      getCentreStatsTask.error ||
      // (errorRetrievingSessionUpdates && errorRetrievingSessionUpdates[qualificationId]) ||
      null,
    totalCount: centreStats?.length,
    totalEntryCount: centreStats ? sumBy(centreStats, (x) => x.entryCount) : undefined,
    filtered,
    centreSummaryStats,
  };
};
