import React, { useMemo } from 'react';
import WarningRoundedIcon from '@material-ui/icons/Warning';
import { Tooltip, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CentreUpdateSummary, RunInfo } from '@cambridgeassessment/checkpoint-dtos';
import { lightFormat } from 'date-fns';

export const CheckpointTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 14,
    padding: '1rem',
  },
}))(Tooltip);

export interface RunTooltipProps {
  generationRunInfo?: RunInfo;
  issueRunInfo?: RunInfo;
  isPrint?: boolean;
  printRunInfo?: RunInfo;
  dataUpdates?: CentreUpdateSummary;
}

export const RunTooltipContent: React.FC<RunTooltipProps> = ({
  generationRunInfo,
  issueRunInfo,
  isPrint,
  dataUpdates,
}) => {
  const generationError = useMemo(() => {
    if (generationRunInfo?.error?.Cause.includes('No data available')) {
      return 'Centre incomplete';
    }
    if (generationRunInfo?.error?.Cause.includes('All subjects are on hold')) {
      return 'Centre on hold';
    }
    if (generationRunInfo?.error?.Cause.includes('One or more EPS and ESM marks are not the same.'))
      return 'One or more EPS and ESM marks are not the same.';
    return 'Something went wrong generating report, if the problem persists contact BTS helpdesk';
  }, [generationRunInfo?.error]);

  const issueError = useMemo(() => {
    if (
      issueRunInfo?.error?.Cause &&
      issueRunInfo?.error?.Cause.includes('All learners and subjects are on hold')
    ) {
      return 'All learners and subjects are on hold.';
    }
    if (
      issueRunInfo?.error?.Cause &&
      issueRunInfo?.error?.Cause.includes('has some learners on hold')
    ) {
      return 'Some learners are on hold.';
    }
    return 'Something went wrong issuing report, if the problem persists contact BTS helpdesk';
  }, [issueRunInfo?.error]);

  return (
    <>
      {generationRunInfo && (
        <Box>
          <b>generated by: </b>
          {generationRunInfo.runBy.name}
          <br />
          <b>generated on: </b>
          {lightFormat(new Date(generationRunInfo.runAt), 'dd-MM-yyyy HH:mm')}
          {generationRunInfo.error !== undefined && (
            <>
              <br />
              <b>error: </b>
              <span>{generationError}</span>
            </>
          )}
        </Box>
      )}
      {issueRunInfo && !isPrint && (
        <Box>
          <b>issued by: </b>
          {issueRunInfo.runBy.name}
          <br />
          <b>issued on: </b>
          {lightFormat(new Date(issueRunInfo.runAt), 'dd-MM-yyyy HH:mm')}
          {issueRunInfo.error !== undefined && (
            <>
              <br />
              <b>error: </b>
              <span>{issueError}</span>
            </>
          )}
        </Box>
      )}
      {issueRunInfo && isPrint && (
        <Box>
          <b>printed by: </b>
          {issueRunInfo.runBy.name}
          <br />
          <b>printed on: </b>
          {lightFormat(new Date(issueRunInfo.runAt), 'dd-MM-yyyy HH:mm')}
          {issueRunInfo.error !== undefined && (
            <>
              <br />
              <b>error: </b>
              <span>{issueError}</span>
            </>
          )}
        </Box>
      )}
      {dataUpdates && (
        <Box data-testid="update-warning">
          <b>There have been changes to the data: </b>
          <br />
          {!!dataUpdates.entryCount && (
            <>
              <b>updated entries: </b>
              {dataUpdates.entryCount}
              <br />
            </>
          )}
          {!!dataUpdates.markCount && (
            <>
              <b>updated marks: </b>
              {dataUpdates.markCount}
              <br />
            </>
          )}
          {!!dataUpdates.learnerCount && (
            <>
              <b>updated learners: </b>
              {dataUpdates.learnerCount}
              <br />
            </>
          )}
          {!!dataUpdates.teachingGroupCount && (
            <>
              <b>updated teaching groups: </b>
              {dataUpdates.teachingGroupCount}
              <br />
            </>
          )}
        </Box>
      )}
    </>
  );
};

export const WarningIcon: React.FC<RunTooltipProps> = ({
  generationRunInfo,
  issueRunInfo,
  dataUpdates,
}) => (
  <CheckpointTooltip
    title={
      <RunTooltipContent
        generationRunInfo={generationRunInfo}
        issueRunInfo={issueRunInfo}
        dataUpdates={dataUpdates}
      />
    }
    arrow
  >
    <div data-testid="warning">
      {generationRunInfo?.error && (
        <WarningRoundedIcon
          style={{ display: 'block' }}
          fontSize="small"
          htmlColor={
            generationRunInfo?.error?.Cause.includes(
              'One or more EPS and ESM marks are not the same.'
            ) ||
            generationRunInfo?.error?.Cause.includes('No data available') ||
            generationRunInfo?.error?.Cause.includes('All subjects are on hold')
              ? '#FCC652'
              : '#FC0303'
          }
        />
      )}
      {issueRunInfo?.error && (
        <WarningRoundedIcon
          style={{ display: 'block' }}
          fontSize="small"
          htmlColor={
            issueRunInfo?.error?.Cause &&
            (issueRunInfo?.error?.Cause.includes('No data available') ||
              issueRunInfo?.error?.Cause.includes('on hold'))
              ? '#FCC652'
              : '#FC0303'
          }
        />
      )}
      {dataUpdates && (
        <WarningRoundedIcon style={{ display: 'block' }} fontSize="small" htmlColor="#FCC652" />
      )}
    </div>
  </CheckpointTooltip>
);
