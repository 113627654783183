import { useMemo } from 'react';
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async';
import { Session } from '@cambridgeassessment/checkpoint-dtos';
import { useApplicationEnv } from 'application.env/react';
import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

export interface SessionInfo {
  retrievingSessions: boolean;
  errorRetrievingSessions: Error | null;
  sessions: Session[];
  mostRecentSeries?: number;
}

export const useSessions = (): SessionInfo => {
  const config = useApplicationEnv();
  const getSessionsMemo = useMemo(() => {
    if (!config) {
      return undefined;
    }
    return {
      url: `${config.API_DOMAIN}/sessions`,
    };
  }, [config]);

  const getSessionsTask = useAsyncTaskAxios<AxiosResponse<Session[]>>(axios, getSessionsMemo);

  const sessions = useMemo(() => getSessionsTask.result?.data || [], [
    getSessionsTask.result?.data,
  ]);

  const mostRecentSeries = useMemo(() => {
    if (!sessions.length) {
      return undefined;
    }

    const activeSessions = sessions.filter((session) => session.status === 'Active');

    if (activeSessions.length) {
      return activeSessions.reduce((mostCurrent, session) =>
        Math.abs(dayjs(mostCurrent.displayName).diff(dayjs())) <
        Math.abs(dayjs(session.displayName).diff(dayjs()))
          ? mostCurrent
          : session
      ).id;
    }

    return sessions[0].id;
  }, [sessions]);

  useAsyncRun(config && getSessionsTask);

  return {
    retrievingSessions:
      (getSessionsTask.started && getSessionsTask.pending) ||
      (!getSessionsTask.result && !getSessionsTask.error),
    errorRetrievingSessions: getSessionsTask.error,
    sessions: getSessionsTask.result?.data || [],
    mostRecentSeries,
  };
};
