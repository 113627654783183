import { useCallback, useMemo } from 'react';
import { AssessmentMark, ExtendedFilter, LearnerMark } from '@cambridgeassessment/checkpoint-dtos';
import { useLocation } from 'react-router-dom';
import { uniq } from 'lodash';

export interface PollerData {
  filter: ExtendedFilter | undefined;
  filters: (
    | {
        value: 0;
        label: string;
        count: number;
        entryCount: number;
      }
    | {
        value: ExtendedFilter;
        label: string;
        count: number;
        entryCount: number;
      }
  )[];
  filterLearners: (
    learnerMarks: LearnerMark[],
    filterValue?: ExtendedFilter | undefined
  ) => LearnerMark[];
}

export const useFilters = (assessmentMarks: AssessmentMark[] | undefined): PollerData => {
  const location = useLocation();
  const useQuery = (): URLSearchParams => new URLSearchParams(location.search);
  const query = useQuery();

  const filter = useMemo<ExtendedFilter | undefined>(() => {
    const filterQuery = query.get('filter');

    if (!filterQuery) {
      return undefined;
    }

    if (Array.isArray(filterQuery)) {
      return filterQuery[0] as ExtendedFilter;
    }

    return filterQuery as ExtendedFilter;
  }, [location]);

  const filterRules = useMemo(
    () => ({
      complete: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter(
          (item) => !Object.values(item.marks).some((value) => !value && value !== 0)
        ),
      incomplete: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) =>
          Object.values(item.marks).some((value) => !value && value !== 0)
        ),
      missing: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) => Object.values(item.marks).some((value) => value === 'M')),
      absent: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) => Object.values(item.marks).some((value) => value === 'A')),
      held: (learnerMarks: LearnerMark[]): LearnerMark[] =>
        learnerMarks.filter((item) => !!item.hold),
    }),
    []
  );

  const filterLearners = useCallback(
    (learnerMarks: LearnerMark[], filterValue?: ExtendedFilter): LearnerMark[] => {
      switch (filterValue) {
        case 'complete':
          return filterRules.complete(learnerMarks);
        case 'incomplete':
          return filterRules.incomplete(learnerMarks);
        case 'missing':
          return filterRules.missing(learnerMarks);
        case 'absent':
          return filterRules.absent(learnerMarks);
        case 'held':
          return filterRules.held(learnerMarks);
        default:
          return learnerMarks;
      }
    },
    []
  );

  const getUniqueLearners = useCallback(
    (
      callback: (learnerMarks: LearnerMark[]) => LearnerMark[],
      assessments: AssessmentMark[] = []
    ): number =>
      assessments?.reduce(
        (a, c) =>
          uniq([...a, ...callback(c.learnerMarks).map((learner) => learner.candidateNumber)]),
        [] as number[]
      ).length || 0,
    []
  );

  const filters = useMemo(
    () => [
      {
        value: 0 as const,
        label: 'All learners',
        count:
          assessmentMarks?.reduce(
            (a, c) => (a > c.learnerMarks.length ? a : c.learnerMarks.length),
            0
          ) || 0,
        entryCount: assessmentMarks?.reduce((a, c) => a + c.learnerMarks.length, 0) || 0,
      },
      {
        value: 'complete' as ExtendedFilter,
        label: 'Complete learners',
        count:
          (assessmentMarks?.reduce(
            (a, c) => (a > c.learnerMarks.length ? a : c.learnerMarks.length),
            0
          ) || 0) - getUniqueLearners(filterRules.incomplete, assessmentMarks),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.complete(c.learnerMarks).length, 0) ||
          0,
      },
      {
        value: 'incomplete' as ExtendedFilter,
        label: 'Incomplete learners',
        count: getUniqueLearners(filterRules.incomplete, assessmentMarks),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.incomplete(c.learnerMarks).length, 0) ||
          0,
      },
      {
        value: 'missing' as ExtendedFilter,
        label: 'Missing learners',
        count: getUniqueLearners(filterRules.missing, assessmentMarks),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.missing(c.learnerMarks).length, 0) || 0,
      },
      {
        value: 'absent' as ExtendedFilter,
        label: 'Absent learners',
        count: getUniqueLearners(filterRules.absent, assessmentMarks),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.absent(c.learnerMarks).length, 0) || 0,
      },
      {
        value: 'held' as ExtendedFilter,
        label: 'Held learners',
        count: getUniqueLearners(filterRules.held, assessmentMarks),
        entryCount:
          assessmentMarks?.reduce((a, c) => a + filterRules.held(c.learnerMarks).length, 0) || 0,
      },
    ],
    [assessmentMarks]
  );

  return {
    filter,
    filters,
    filterLearners,
  };
};
