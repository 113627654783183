import { log } from '@cambridgeassessment/aws-lambda-logging';
import { DynamoDB } from 'aws-sdk';
import { HoldUpdate } from '../types';

export const holdLearner = async (
  qualificationId: number,
  sessionId: number,
  centreId: string,
  assessmentHold: HoldUpdate[]
): Promise<string> => {
  log.info(
    `Put the hold info on DynamoDB for session:${sessionId}, qualificationId:${qualificationId}, centre: ${centreId}`
  );
  const dynamoDb: DynamoDB.DocumentClient = new DynamoDB.DocumentClient({});
  log.info(JSON.stringify(assessmentHold));
  const TableName = process.env.DYNAMODBTABLE || '';
  const partitionKey = `${sessionId}-${qualificationId}`;
  const assessment = assessmentHold.map((code) => ({
    ...code,
    primaryKey: {
      partitionKey,
      sortKey: `HOLD-${centreId}-${code.assessmentCode}`,
    },
  }));

  const promises = assessment.map((key) => {
    // learnel level
    if (key.learnersOnHold) {
      return dynamoDb
        .put({
          TableName,
          Item: { ...key.primaryKey, learnersOnHold: key.learnersOnHold, gsi1sk: 'HOLD' },
        })
        .promise();
    }
    // centre-subject level
    if (key.hold) {
      return dynamoDb
        .put({
          TableName,
          Item: { ...key.primaryKey, gsi1sk: 'HOLD' },
        })
        .promise();
    }
    return dynamoDb.delete({ Key: key.primaryKey, TableName }).promise();
  });
  await Promise.all(promises);
  return 'hold/release status updated';
};

export default holdLearner;
