import {
  getAverage,
  getAverageByTeachingGroup,
  getAverageScore,
  getGroupedAverages,
  getLearnersPerAssessment,
  getStrandAverageForEachSubject,
  getLearnersWithAssessmentResults,
  getStrandScoreAverage,
  groupByAge,
  groupAgesByScore,
  groupLearnersByScore,
  getAge,
} from './aggregates';

import { getQualification, getQualifications } from './qualificationMapper';
import {
  groupCentreStats,
  groupCentreStatsWithUpdates,
  filterPredicates,
  filterPredicatesWithUpdates,
  filterFields,
  filterFieldsWithUpdates,
} from './centreStatsGrouper';
import { axiosHeaders, isAxiosError, isUnauthorized } from './middlewares/axios-headers';
import {
  InconsistentDataError,
  InvalidInputError,
  NotFoundError,
} from './middlewares/customErrors';
import { capitalizeAllExcept } from './helpers';
import { getHolds, getHoldStatus } from './manageHolds/getHoldStatus';
import { holdLearner } from './manageHolds/holdLearner';
import { removeOutdatedHolds } from './manageHolds/removeOutdatedHolds';
import { getAllData } from './dynamoDB';

export {
  axiosHeaders,
  getAge,
  getAverage,
  getAverageByTeachingGroup,
  getAverageScore,
  getGroupedAverages,
  getLearnersPerAssessment,
  getQualification,
  getStrandAverageForEachSubject,
  getLearnersWithAssessmentResults,
  getStrandScoreAverage,
  groupByAge,
  groupAgesByScore,
  groupLearnersByScore,
  groupCentreStats,
  groupCentreStatsWithUpdates,
  filterPredicates,
  filterPredicatesWithUpdates,
  filterFields,
  filterFieldsWithUpdates,
  InconsistentDataError,
  InvalidInputError,
  NotFoundError,
  capitalizeAllExcept,
  getHolds,
  getHoldStatus,
  holdLearner,
  removeOutdatedHolds,
  isAxiosError,
  isUnauthorized,
  getAllData,
  getQualifications,
};

export {
  getAverageByTeachingGroupV2,
  getAverageScoreV2,
  getAverageV2,
  getGroupedAveragesV2,
  getStrandAverageForEachSubjectV2,
  getStrandScoreAverageV2,
  groupAgesByScoreV2,
  groupLearnersByScoreV2,
} from './aggregatesV2';
