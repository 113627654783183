import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Filter, CentreStatsSummary } from '@cambridgeassessment/checkpoint-dtos';
import { FilterTabLabel } from './Tabs/FilterTabLabel';

export interface SessionQualStatProps {
  filter?: Filter;
  displayLabel: string;
  sessionId: number;
  qualificationId: number;
  stats: CentreStatsSummary;
  'data-testid'?: string;
}

export const SessionQualStat: React.FC<SessionQualStatProps> = ({
  filter,
  displayLabel,
  sessionId,
  qualificationId,
  stats,
  'data-testid': dataTestId,
}): JSX.Element => {
  const filterUrlElement = filter ? `?filter=${filter}` : '';
  return (
    <Box alignSelf="flex-end" mr="3rem" data-testid={dataTestId}>
      <FilterTabLabel
        label={displayLabel}
        primaryCount={stats.centreCount}
        secondaryCount={stats.entryCount}
        secondaryCountType={filter === 'withUpdates' ? 'updates' : 'entries'}
      />
      <Link
        component={RouterLink}
        to={`/session/${sessionId}/qualification/${qualificationId}${filterUrlElement}`}
      >
        <Typography color="inherit" variant="subtitle2" gutterBottom>
          View
        </Typography>
      </Link>
    </Box>
  );
};
