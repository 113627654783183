import { isValid, format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputLabel, Hidden } from '@material-ui/core';
import { StyledDatePicker, DatePickerContainer } from './DatePicker.styles';

type DatePickerProps = {
  date?: Date;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  onChange: (value: Date) => void;
  disabled?: boolean;
};

type DatePickerValidationProps = {
  minDate?: Date;
  maxDate?: Date;
  minDateMessage: string;
  maxDateMessage: string;
};

const DatePicker = ({
  date,
  label,
  minDate,
  maxDate,
  disabled,
  onChange,
}: DatePickerProps): JSX.Element => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>();

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  const handleDateChange = (value: Date | null): void => {
    setSelectedDate(value);
    if (isValid(value) && value !== null && minDate !== undefined && value >= minDate) {
      onChange(value);
    }
  };

  const validationProps = useMemo((): DatePickerValidationProps => {
    if (!disabled) {
      return {
        minDate,
        minDateMessage: minDate ? `Minimum allowed date is ${format(minDate, 'yyyy-dd-MM')}` : '',
        maxDate,
        maxDateMessage: maxDate ? `Maximum allowed date is ${format(maxDate, 'yyyy-dd-MM')}` : '',
      };
    }
    return {} as DatePickerValidationProps;
  }, [disabled]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePickerContainer>
        <Hidden smDown>
          <InputLabel>{label}</InputLabel>
        </Hidden>
        <StyledDatePicker
          id="date-picker-dialog"
          disabled={disabled}
          placeholder="yyyy-dd-MM"
          format="yyyy-dd-MM"
          value={selectedDate}
          {...validationProps}
          onChange={handleDateChange}
          inputProps={{ readOnly: true }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </DatePickerContainer>
    </MuiPickersUtilsProvider>
  );
};

DatePicker.defaultProps = {
  date: null,
  minDate: undefined,
  maxDate: undefined,
  label: '',
  disabled: false,
};

export default DatePicker;
