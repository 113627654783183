import React, { useMemo } from 'react';
import { Box, LinearProgress, Typography, CircularProgress } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/Warning';
import { CheckpointTooltip } from '../WarningIcon/WarningIcon';

interface ProgressBarProps {
  increment: number;
  errorCount?: number;
  label: string;
  subLabel: string;
  total?: number;
}

const ProgressBar = ({
  increment,
  label,
  subLabel,
  errorCount,
  total,
}: ProgressBarProps): JSX.Element => {
  const progressPercentage = useMemo(() => {
    if (total) return (increment * 100) / total;
    return 0;
  }, [increment, total]);

  const errorCountLabel = useMemo(() => {
    if (errorCount === 1) {
      return `${errorCount} centre has errored`;
    }
    return `${errorCount || 0} centres have errored`;
  }, [errorCount]);

  const incrementLabel = useMemo(() => {
    if (errorCount === 0) {
      return <>{increment}</>;
    }
    return (
      <CheckpointTooltip
        title={
          <Box display="flex" alignItems="center">
            <WarningRoundedIcon htmlColor="#FCC652" />
            <Box display="inline" color="white" ml={1}>
              <Typography color="inherit" variant="subtitle2">
                {errorCountLabel}
              </Typography>
            </Box>
          </Box>
        }
        data-testid="progress-error-tooltip"
      >
        <Box display="inline" color="#FCC652">
          {increment}
        </Box>
      </CheckpointTooltip>
    );
  }, [increment, errorCount]);

  return (
    <Box alignSelf="center" mr={1}>
      <Box display="flex">
        <Box minWidth={35} mb={1}>
          <Typography variant="subtitle1" gutterBottom>
            {label}
          </Typography>
        </Box>
        <Box ml="auto">
          <Box display="flex" alignItems="center">
            <Box mr={1} textAlign="right">
              {total !== undefined && total > 0 && (
                <>
                  <Typography variant="caption" color="textSecondary">
                    {subLabel}
                  </Typography>
                  <Typography variant="caption" className="semi-bold" display="block">
                    {incrementLabel}
                    {` / ${total}`}
                  </Typography>
                </>
              )}
            </Box>
            <CircularProgress size="24px" />
          </Box>
        </Box>
      </Box>
      <Box minWidth={250}>
        <LinearProgress variant="determinate" value={progressPercentage} />
      </Box>
    </Box>
  );
};

ProgressBar.defaultProps = {
  total: 0,
  errorCount: 0,
};

export default ProgressBar;
