import { differenceInYears } from 'date-fns';
import { mapValues, keyBy, flatMap, groupBy, mean, meanBy, orderBy, round } from 'lodash';
import {
  AssessmentResult,
  GroupResult,
  LearnerResult,
  LearnerAssessmentResults,
} from '@cambridgeassessment/checkpoint-dtos';

const getAge = (date: Date): number => differenceInYears(Date.now(), new Date(date));

const getAverage = (data: number[]): number => {
  const average = round(mean(data), 1);

  if (average < 0) return 0;
  if (average > 6) return 6.0;
  return average;
};

const getAverageScore = (learnerResults: LearnerResult[]): number =>
  getAverage(learnerResults.map((item) => item.score));

const getStrandScoreAverage = (
  learnerResults: LearnerResult[]
): { strand: string; score: number }[] => {
  const flatStrands = flatMap(learnerResults, (x) =>
    x.strandScores?.sort((a, b) => a?.order - b?.order)
  );
  const grouped = groupBy(flatStrands, (x) => x?.strand);
  return Object.keys(grouped).map((key) => ({
    strand: key,
    score: round(
      meanBy(grouped[key], (x) => x?.score),
      1
    ),
  }));
};

const getAverageByTeachingGroup = (assessmentResult: AssessmentResult): GroupResult[] => {
  const centreAverage = getAverageScore(assessmentResult.learnerResults);
  const centreStrandAverages = getStrandScoreAverage(assessmentResult.learnerResults);
  const grouped = groupBy(assessmentResult.learnerResults, 'teachingGroupNumber');
  const groupedAverage = mapValues(grouped, (y) => getAverage(y.map((x) => x.score)));
  return orderBy(
    Object.keys(grouped).map((key) => ({
      score: groupedAverage[key],
      teachingGroupNumber: key,
      teachingGroupName: grouped[key][0].teachingGroupName,
      strandAverages: getStrandScoreAverage(grouped[key]).map((strand) => ({
        ...strand,
        centreAverage: centreStrandAverages.filter(
          (centreStrandAverage) => centreStrandAverage.strand === strand.strand
        )[0].score,
      })),
      numberOfLearners: grouped[key].length,
      learnerResults: orderBy(grouped[key], 'name', 'asc'),
      centreAverage,
    })),
    'teachingGroupName',
    'asc'
  );
};

const getStrandAverageForEachSubject = (
  assessmentResults: AssessmentResult[]
): { [key: string]: { strand: string; score: number }[] } => {
  const groupedLearners: { [key: string]: AssessmentResult } = keyBy(
    assessmentResults,
    (x) => x.assessmentCode
  );
  return mapValues(groupedLearners, (y) => getStrandScoreAverage(y.learnerResults));
};

const getGroupedAverages = (assessmentResults: AssessmentResult[]): { [key: string]: number } =>
  mapValues(
    keyBy(assessmentResults, (x) => x.assessmentCode),
    (y) => getAverage(y.learnerResults.map((x) => x.score))
  );

const getLearnersPerAssessment = (
  assessmentResults: AssessmentResult[]
): { [key: number]: number } =>
  mapValues(
    keyBy(assessmentResults, (x) => x.assessmentCode),
    (x) => x.learnerResults.length
  );

const groupByAge = (
  learnerResult: LearnerResult[],
  andUnder: number,
  mainGroup: number,
  andOver: number
): { [key: string]: LearnerResult[] } =>
  learnerResult.reduce((accumulator, current) => {
    if (!current.dob) {
      return {
        ...accumulator,
        notProvided: accumulator.notProvided ? [...accumulator.notProvided, current] : [current],
      };
    }

    if (getAge(current.dob) <= andUnder) {
      return {
        ...accumulator,
        [andUnder]: accumulator[andUnder] ? [...accumulator[andUnder], current] : [current],
      };
    }

    if (getAge(current.dob) === mainGroup) {
      return {
        ...accumulator,
        [mainGroup]: accumulator[mainGroup] ? [...accumulator[mainGroup], current] : [current],
      };
    }

    return {
      ...accumulator,
      [andOver]: accumulator[andOver] ? [...accumulator[andOver], current] : [current],
    };
  }, {} as { [key: string]: LearnerResult[] });

const groupLearnersByScore = (
  learnerResult: LearnerResult[],
  strand?: string
): { [key: string]: LearnerResult[] } =>
  learnerResult.reduce((accumulator, current) => {
    const strandScore = current.strandScores?.find(
      (currentScore) => currentScore.strand === strand
    );

    if (strand && !strandScore?.score) {
      return accumulator;
    }

    const score = strand && strandScore?.score ? strandScore.score : current.score;

    if (score < 1) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { ...accumulator, 0: accumulator[0] ? [...accumulator[0], current] : [current] };
    }
    if (score >= 6) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { ...accumulator, 6: accumulator[6] ? [...accumulator[6], current] : [current] };
    }

    const key = Math.trunc(score);
    return {
      ...accumulator,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [key]: accumulator[key] ? [...accumulator[key], current] : [current],
    };
  }, {});

const groupAgesByScore = (
  learnerResult: LearnerResult[],
  andUnder: number,
  mainGroup: number,
  andOver: number,
  strand?: string
): {
  [key: string]: {
    [key: string]: LearnerResult[];
  };
} => {
  const groupedLearners = groupLearnersByScore(learnerResult, strand);

  return Object.keys(groupedLearners).reduce(
    (accumulator, current) => ({
      ...accumulator,
      [current]: groupByAge(groupedLearners[current], andUnder, mainGroup, andOver),
    }),
    {}
  );
};

const getLearnersWithAssessmentResults = (
  assessmentResults: AssessmentResult[]
): {
  [key: string]: LearnerAssessmentResults;
} =>
  assessmentResults.reduce(
    (a, c) => ({
      ...a,
      ...mapValues(keyBy(c.learnerResults, 'candidateNumber'), (learner: LearnerResult) => ({
        ...a[learner.candidateNumber],
        name: learner.name,
        assessments: [
          ...(a[learner.candidateNumber] ? a[learner.candidateNumber].assessments : []),
          {
            assessmentName: c.assessmentName,
            score: learner.score,
          },
        ],
      })),
    }),
    {} as { [key: string]: LearnerAssessmentResults }
  );

export {
  getAverage,
  getAverageByTeachingGroup,
  getLearnersWithAssessmentResults,
  getAverageScore,
  getAge,
  getGroupedAverages,
  getLearnersPerAssessment,
  getStrandAverageForEachSubject,
  getStrandScoreAverage,
  groupByAge,
  groupAgesByScore,
  groupLearnersByScore,
};
