import React from 'react';
import { lightFormat } from 'date-fns';
import { Box } from '@material-ui/core';
import { ReportDownloadInfo } from '@cambridgeassessment/checkpoint-dtos';
import WarningMessage from '../WarningMessage/WarningMessage';

interface GeneratedInfoLabelProps {
  reportDownloadInfo?: ReportDownloadInfo;
  runningFunction?: boolean;
}

export const GeneratedInfoLabel: React.FC<GeneratedInfoLabelProps> = ({
  reportDownloadInfo,
  runningFunction,
}) => {
  const formattedDate = reportDownloadInfo?.runInfo
    ? lightFormat(new Date(reportDownloadInfo.runInfo.runAt), 'dd-MM-yyyy HH:mm')
    : '';

  if (
    reportDownloadInfo?.status === 'IN QUEUE' ||
    reportDownloadInfo?.status === 'STARTED' ||
    runningFunction
  ) {
    return (
      <Box paddingTop="4px" display="flex" flexDirection="row" alignItems="center">
        <Box paddingLeft="4px">
          <b>In progress</b>
        </Box>
      </Box>
    );
  }
  if (reportDownloadInfo?.status === 'FAILED') {
    return (
      <Box paddingTop="4px" display="flex" flexDirection="row" alignItems="center">
        <WarningMessage isError message="Last report generation failed." />
      </Box>
    );
  }

  if (reportDownloadInfo?.status === 'COMPLETE') {
    return (
      <Box paddingLeft="4px">
        <b>generated by:</b> {reportDownloadInfo.runInfo.runBy.name}
        <br />
        <b>generated on:</b> {formattedDate}
      </Box>
    );
  }

  return (
    <Box paddingTop="4px" display="flex" flexDirection="row" alignItems="center">
      {!reportDownloadInfo?.runInfo && (
        <Box paddingLeft="4px">
          <b>Not yet run</b>
        </Box>
      )}
    </Box>
  );
};
