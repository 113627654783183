import { AxiosError, AxiosResponse } from 'axios';

export const isNotFound = (axiosResponse: AxiosResponse | undefined): boolean =>
  !!axiosResponse && axiosResponse.status === 404;

export const isAxiosError = (error: Error): error is AxiosError =>
  (error as AxiosError).isAxiosError !== undefined;

export const isForbidden = (axiosResponse: AxiosResponse | undefined): boolean =>
  !!axiosResponse && axiosResponse.status === 403;
