import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import { colours } from '@cambridgeassessment/cambridge-ui';
import { Filter, ExtendedFilter } from '@cambridgeassessment/checkpoint-dtos';

interface FilterTabProps {
  label?: React.ReactNode;
  value: Filter | ExtendedFilter | 0;
}

export const FilterTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      borderBottom: `2px solid ${colours.monochromeLight}`,
      minWidth: 125,
      [theme.breakpoints.up('lg')]: {
        minWidth: 150,
      },
    },
    wrapper: {
      alignItems: 'flex-start',
      alignSelf: 'flex-end',
    },
  })
)((props: FilterTabProps) => <Tab {...props} />);
