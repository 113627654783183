import React from 'react';

interface ChevronProps {
  className: string;
}

const ChevronDown = ({ className }: ChevronProps): JSX.Element => (
  <svg
    className={className}
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.5L5 5.5L1 1.5"
      stroke="#037FAC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronDown;
