import React, { ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useLocation, useHistory } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';
import WarningRoundedIcon from '@material-ui/icons/Warning';
import {
  Box,
  Container,
  IconButton,
  Link,
  Tabs,
  Typography,
  FormControlLabel,
  Hidden,
  Switch,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Filter,
  CentreStats,
  ReportIssuerStatus,
  ReportGeneratorStatus,
  ReportDownloadInfo,
  ProcessType,
} from '@cambridgeassessment/checkpoint-dtos';
import { getQualification } from '@cambridgeassessment/checkpoint-utils';
import { Breadcrumbs, Button } from '@cambridgeassessment/cambridge-ui';
import CreateIcon from '@material-ui/icons/Create';

// Utils
import { useCentreStats } from '../utils/useCentreStats';
import { useGrading } from '../utils/useGradingStatus';
import { useSessions } from '../utils/useSessions';
import { usePollStatus } from '../utils/usePollStatus';
import { useResultReleaseDate } from '../utils/useResultReleaseDate';
import { useGenerateDownloadReport } from '../utils/useGenerateDownloadReport';
import { GenerateReportResponse, useGenerateReport } from '../utils/useGenerateReport';
import { IssueReportResponse, useIssueReport } from '../utils/useIssueReport';
import { useHoldStatus } from '../utils/useHoldStatus';
import { contains } from '../utils/helpers';

// Components
import DatePicker from '../components/DatePicker/DatePicker';
import DialogWindow from '../components/DialogWindow/DialogWindow';
import DownloadS3FileButton from '../components/DownloadS3FileButton';
import FilterCheckbox from '../components/FilterCheckbox/FilterCheckbox';
import { RunInfoLabel } from '../components/RunInfoLabel/RunInfoLabel';
import { GeneratedInfoLabel } from '../components/GeneratedInfoLabel/GeneratedInfoLabel';
import {
  WarningIcon,
  RunTooltipContent,
  CheckpointTooltip,
} from '../components/WarningIcon/WarningIcon';
import { FilterTab } from '../components/Tabs/FilterTab';
import { FilterTabLabel } from '../components/Tabs/FilterTabLabel';
import HoldOptions from '../components/HoldOptions/HoldOptions';
import { LabelWithIndicator } from '../components/LabelWithIndicator';
import Loader from '../components/Loader/Loader';
import MoreActionMenu from '../components/MoreActionMenu/MoreActionMenu';
import NoResultsView, { Error, NotFound } from '../components/NoResultsView/NoResultsView';
import { PageLoader } from '../components/PageLoader';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import { ReportViewerModal } from '../components/ReportViewer/ReportViewerModal';
import SearchInput from '../components/SearchInput/SearchInput';
import Table from '../components/Table/Table';
import WarningMessage from '../components/WarningMessage/WarningMessage';
import ErrorMessage from '../components/ErrorMessage/ErrorMessage';
import SessionExpired from '../components/SessionExpired/SessionExpired';

// Icons
import { ReactComponent as IconPdf } from '../icons/iconPdf.svg';
import { ReactComponent as IconXls } from '../icons/iconXls.svg';
import { ReactComponent as IconCertificate } from '../icons/iconCertificate.svg';
import { sortGeneratedReports } from '../utils/sortGeneratedReports';

export interface SessionQualPath {
  id: string;
  qid: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    warning: {
      justifySelf: 'end',
    },
    switch: {
      marginRight: '10px',
    },
    switchLabel: {
      marginLeft: 0,
    },
  })
);

const getFilterLabel = (filter?: Filter): string => {
  switch (filter) {
    case 'complete':
      return 'Complete';
    case 'incomplete':
      return 'Incomplete';
    case 'absent':
      return 'With Absents';
    case 'missing':
      return 'Missing';
    case 'held':
      return 'Held';
    case 'inError':
      return 'Error';
    case 'outdated':
      return 'Outdated';
    // INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)
    //
    // case 'withUpdates':
    //   return 'With Updates';
    default:
      return 'All Centres';
  }
};

const checkSfRunning = (
  triggering: boolean,
  latestStatus?: ReportGeneratorStatus | ReportIssuerStatus,
  triggeredResponse?: GenerateReportResponse | IssueReportResponse
): boolean => {
  if (
    triggering ||
    (!latestStatus && triggeredResponse?.executionArn) ||
    latestStatus?.sfStatus === 'STARTED'
  ) {
    return true;
  }
  if (
    (latestStatus?.sfStatus === 'COMPLETE' || latestStatus?.sfStatus === 'FAILED') &&
    latestStatus?.executionId === triggeredResponse?.executionArn
  ) {
    return false;
  }
  if (triggeredResponse && latestStatus?.executionId === triggeredResponse?.executionArn)
    return true;
  return false;
};

export const SessionQualificationProgressPage: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const { id, qid } = useParams<SessionQualPath>();
  const location = useLocation();
  const history = useHistory();

  const [openConfirmOneCentreModal, setConfirmOneCentreOpen] = useState(false);
  const [holdOrReleaseModal, setHoldOrReleaseModal] = useState(false);
  const [holdOrReleaseUpdates, setHoldOrReleaseUpdates] = useState<Record<string, string>>({});
  const [selectedCentre, setSelectedCentre] = useState<string | undefined>(undefined);
  const [holdCompleteModal, setHoldCompleteModal] = useState(false);
  const [dateInputDisabled, setDateDisabled] = useState(true);
  const [e2lFilterChecked, setE2lFilterChecked] = useState(false);
  const [issueReportModal, setIssueReportModal] = useState(false);
  const [isIssueReportsOpen, setIssueReportsConfirmation] = useState(false);
  const [soaPrintModal, setSoaPrintModal] = useState(false);
  const [isPrintSoasConfirmationOpen, setSoasPrintConfirmation] = useState(false);
  const [forceIssue, setForceIssue] = useState(false);
  const [generateFailedOnly, setGenerateFailedOnly] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [selectedKey, setSelectedKey] = useState<
    { key: string; fileName: string; version?: string } | undefined
  >(undefined);
  const [showViewer, setShowViewer] = useState(false);
  const [open, setOpen] = useState(false);
  const [downloadReportOpen, downloadReportSetOpen] = useState(false);

  const { sessions, retrievingSessions, errorRetrievingSessions } = useSessions();
  const {
    retrievingReleaseDate,
    errorRetrievingReleaseDate,
    errorSettingReleaseDate,
    releaseDate,
    setReleaseDate,
  } = useResultReleaseDate(parseInt(id, 10), parseInt(qid, 10));

  const useQuery = (): URLSearchParams =>
    useMemo(() => new URLSearchParams(location.search), [location.search]);

  const query = useQuery();

  const reportVersion = useMemo(() => {
    const selectedSession = sessions.find((session) => String(session.id) === id);
    // Report version 2 starts with year 2023 and onwards.
    // Default version will be 2 as it is more relevant than version 1
    return Number(selectedSession?.year) <= 2022 ? 1 : 2;
  }, [id, sessions]);

  const {
    generateReportResponse,
    triggeringGenerateReport,
    errorTriggeringGenerateReport,
    startGenerateReport,
  } = useGenerateReport(id, qid, reportVersion);

  const {
    generateDownloadReportResponse,
    triggeringGenerateDownloadReport,
    errorTriggeringGenerateDownloadReport,
    startGenerateDownloadReport,
  } = useGenerateDownloadReport(id, qid);

  const {
    issueReportResponse,
    triggeringIssueReport,
    errorTriggeringIssueReport,
    startIssueReport,
  } = useIssueReport(id, qid, ProcessType.ISSUE, reportVersion);

  const {
    issueReportResponse: soaPrintResponse,
    triggeringIssueReport: triggeringSoaPrint,
    errorTriggeringIssueReport: errorTriggeringSoaPrint,
    startIssueReport: startSoaPrint,
  } = useIssueReport(id, qid, ProcessType.SOA, reportVersion);

  const {
    firstPollStatusBack,
    lastReportStatus: lastReportGeneratorStatus,
    pollError: generatePollError,
  } = usePollStatus(
    parseInt(id, 10),
    parseInt(qid, 10),
    'reportGeneratorStatus',
    generateReportResponse?.executionName
  ) as { firstPollStatusBack: boolean; pollError: Error; lastReportStatus: ReportGeneratorStatus };

  const {
    firstPollStatusBack: firstIssuerPollStatusBack,
    lastReportStatus: lastReportIssuerStatus,
    pollError: issuePollError,
  } = usePollStatus(
    parseInt(id, 10),
    parseInt(qid, 10),
    `reportIssuerStatus/${ProcessType.ISSUE}`,
    issueReportResponse?.executionArn
  ) as { firstPollStatusBack: boolean; pollError: Error; lastReportStatus: ReportIssuerStatus };

  const {
    firstPollStatusBack: firstSoaPollStatusBack,
    lastReportStatus: lastSoaPrintStatus,
    pollError: soaPollError,
  } = usePollStatus(
    parseInt(id, 10),
    parseInt(qid, 10),
    `reportIssuerStatus/${ProcessType.SOA}`,
    soaPrintResponse?.executionArn
  ) as { firstPollStatusBack: boolean; pollError: Error; lastReportStatus: ReportIssuerStatus };

  const { gradingAvailable, retrievingGradingData, errorRetrievingGradingData } = useGrading({
    sessionId: parseInt(id, 10),
    qualificationId: parseInt(qid, 10),
  });

  const {
    firstPollStatusBack: firstQCReportPollStatusBack,
    pollError: errorQCPoll,
    lastReportStatus: lastQCReportStatus,
  } = usePollStatus(parseInt(id, 10), parseInt(qid, 10), 'reportDownloadInfo') as {
    firstPollStatusBack: boolean;
    pollError: Error;
    lastReportStatus: ReportDownloadInfo;
  };

  const {
    holdStatus,
    retrievingHoldStatus,
    errorRetrievingHoldStatus,
    retrievingSetHoldStatusResponse,
    errorSettingHoldStatus,
    setHoldStatus,
    setHoldStatusResponse,
  } = useHoldStatus(
    parseInt(id, 10),
    parseInt(qid, 10),
    selectedCentre,
    selectedCentre,
    holdOrReleaseModal
  );

  const handleReleaseDateChange = useCallback((date: Date) => {
    const newDate = date.setUTCHours(0, 1);
    setReleaseDate(new Date(newDate));
  }, []);

  const latestRelevantGenerationStatus = useMemo(() => {
    if (
      triggeringGenerateReport &&
      lastReportGeneratorStatus &&
      ((generateReportResponse &&
        lastReportGeneratorStatus.runAt < generateReportResponse.startDate) ||
        !generateReportResponse)
    ) {
      return undefined;
    }
    return lastReportGeneratorStatus;
  }, [lastReportGeneratorStatus, generateReportResponse, triggeringGenerateReport]);

  const reportGeneratorRunning = useMemo(
    () =>
      checkSfRunning(
        triggeringGenerateReport,
        latestRelevantGenerationStatus,
        generateReportResponse
      ),
    [latestRelevantGenerationStatus, triggeringGenerateReport, generateReportResponse]
  );

  const latestRelevantIssuerStatus = useMemo(() => {
    if (
      triggeringIssueReport &&
      lastReportIssuerStatus &&
      ((issueReportResponse && lastReportIssuerStatus.runAt < issueReportResponse.startDate) ||
        !issueReportResponse)
    ) {
      return undefined;
    }
    return lastReportIssuerStatus;
  }, [triggeringIssueReport, lastReportIssuerStatus, issueReportResponse]);

  const reportIssuerRunning = useMemo(
    () => checkSfRunning(triggeringIssueReport, latestRelevantIssuerStatus, issueReportResponse),
    [latestRelevantIssuerStatus, triggeringIssueReport, issueReportResponse]
  );

  const latestRelevantSoaPrintStatus = useMemo(() => {
    if (
      triggeringSoaPrint &&
      lastSoaPrintStatus &&
      ((soaPrintResponse && lastSoaPrintStatus.runAt < soaPrintResponse.startDate) ||
        !soaPrintResponse)
    ) {
      return undefined;
    }
    return lastSoaPrintStatus;
  }, [triggeringSoaPrint, lastSoaPrintStatus, soaPrintResponse]);

  const soaPrintRunning = useMemo(
    () => checkSfRunning(triggeringSoaPrint, latestRelevantSoaPrintStatus, soaPrintResponse),
    [latestRelevantSoaPrintStatus, triggeringSoaPrint, soaPrintResponse]
  );

  const filter = useMemo<Filter | undefined>(() => {
    const filterQuery = query.get('filter');

    if (!filterQuery) {
      return undefined;
    }

    if (Array.isArray(filterQuery)) {
      return filterQuery[0] as Filter;
    }

    return filterQuery as Filter;
  }, [location]);
  useEffect(() => {
    setGenerateFailedOnly(false);
  }, [filter]);
  const {
    filtered,
    retrievingCentreStats,
    errorRetrievingCentreStats,
    totalCount,
    totalEntryCount,
    centreSummaryStats,
  } = useCentreStats(
    {
      sessionId: parseInt(id, 10),
      qualificationId: parseInt(qid, 10),
      filter,
    },
    reportVersion
  );

  useMemo(() => {
    if (
      triggeringGenerateDownloadReport ||
      lastQCReportStatus?.status === 'IN QUEUE' ||
      lastQCReportStatus?.status === 'STARTED'
    ) {
      return setButtonDisabled(true);
    }
    return setButtonDisabled(false);
  }, [lastQCReportStatus, triggeringGenerateDownloadReport]);

  const debouncedQuery = useDebounce(searchQuery, 300);
  const reportFilePrefix = (centreNumber: string): string => `${id}-${qid}-${centreNumber}`;
  const showItem = (centreNumber: string, key: string, version?: string): void => {
    setSelectedKey({ key, version, fileName: `${reportFilePrefix(centreNumber)}.pdf` });
    setShowViewer(true);
  };

  const toggle = useCallback((): void => {
    setShowViewer((state) => !state);
  }, []);

  const confirmOneCentreModal = useCallback((centreId: string): void => {
    setSelectedCentre(centreId);
    setConfirmOneCentreOpen(true);
  }, []);

  const handleCloseOneCentre = useCallback((): void => {
    setConfirmOneCentreOpen(false);
  }, []);

  const confirmIssueReportModal = useCallback((centreId: string): void => {
    setSelectedCentre(centreId);
    setIssueReportModal(true);
  }, []);

  const handleCloseIssueReport = useCallback((): void => {
    setIssueReportModal(false);
  }, []);

  const confirmSoaPrintModal = useCallback((centreId: string): void => {
    setSelectedCentre(centreId);
    setSoaPrintModal(true);
  }, []);

  const handleCloseSoaPrint = useCallback((): void => {
    setSoaPrintModal(false);
  }, []);

  const confirmHoldCompleteModal = useCallback(async (): Promise<void> => {
    setHoldOrReleaseModal(false);
    setHoldCompleteModal(true);
    setHoldStatus(
      Object.keys(holdOrReleaseUpdates).map((key: string) => ({
        assessmentCode: key,
        hold: holdOrReleaseUpdates[key] === 'hold',
      }))
    );
  }, [holdOrReleaseUpdates]);

  const handleCloseHoldComplete = useCallback((): void => {
    setHoldOrReleaseUpdates({});
    setHoldCompleteModal(false);
  }, []);

  const confirmHoldOrReleaseModal = useCallback((centreId: string): void => {
    setSelectedCentre(centreId);
    setHoldOrReleaseModal(true);
  }, []);

  const handleCloseHoldOrRelease = useCallback((): void => {
    setHoldOrReleaseUpdates({});
    setHoldOrReleaseModal(false);
  }, []);

  const handleClose = useCallback((): void => {
    setOpen(false);
  }, []);

  const handleClickOpen = useCallback((): void => {
    setOpen(true);
  }, []);

  const downloadReportHandleClose = useCallback((): void => {
    downloadReportSetOpen(false);
  }, []);
  const downloadReportHandleClickOpen = useCallback((): void => {
    downloadReportSetOpen(true);
  }, []);

  const tableData = useMemo(
    () =>
      filtered &&
      filtered.filter(
        (item) =>
          (!debouncedQuery ||
            contains(item.name, debouncedQuery) ||
            contains(item.id, debouncedQuery)) &&
          (e2lFilterChecked ? item.hasTeachingGroupEPrefix : true)
      ),
    [
      filtered,
      debouncedQuery,
      reportGeneratorRunning,
      reportIssuerRunning,
      gradingAvailable,
      e2lFilterChecked,
    ]
  );

  const filterLabel = useMemo(() => getFilterLabel(filter), [filter]);

  const currentSession = useMemo(
    () => sessions.find((session) => String(session.id) === id)?.displayName,
    [id, sessions]
  );

  const handleSearch = useCallback((value: string | null): void => {
    setSearchQuery(value);
  }, []);

  const handleSingleFilter = useCallback((): void => setE2lFilterChecked(!e2lFilterChecked), [
    e2lFilterChecked,
  ]);

  const toggleDateInput = useCallback((): void => setDateDisabled(!dateInputDisabled), [
    dateInputDisabled,
  ]);

  const issueReportConfirmationMsg = useMemo(() => {
    const selected = tableData?.find((item) => item.id === selectedCentre);
    if (selected && selected.incompleteCount > 0) {
      return 'This centre contains incomplete entries, please confirm you wish to proceed';
    }
    if (selected && selected.hasHeldLearners) {
      return 'This centre contains held entries, please confirm you wish to proceed';
    }
    return 'Please confirm you wish to proceed';
  }, [selectedCentre, tableData]);

  const retrievingPageDependencies = useMemo(
    () =>
      retrievingSessions ||
      retrievingGradingData ||
      !firstPollStatusBack ||
      !firstIssuerPollStatusBack ||
      !firstQCReportPollStatusBack ||
      !firstSoaPollStatusBack ||
      retrievingReleaseDate,
    [
      retrievingSessions,
      firstPollStatusBack,
      firstIssuerPollStatusBack,
      firstQCReportPollStatusBack,
      firstSoaPollStatusBack,
      retrievingReleaseDate,
      retrievingGradingData,
    ]
  );

  // put in here any page deps where you want to error whole page,
  // if instead you want inline error somewhere in the page, handle that separately.
  const pageDependeciesError = useMemo(() => errorRetrievingSessions !== null, [
    errorRetrievingSessions,
  ]);

  const headerColumns = useMemo(
    () => [
      {
        header: 'Centre name',
        accessor: 'name',
        renderCell: (row: CentreStats) => (
          <Typography variant="subtitle2">
            <Link
              color="primary"
              component={RouterLink}
              to={`/session/${id}/qualification/${qid}/centre/${String(row.id)}`}
            >
              {row.name}
            </Link>
          </Typography>
        ),
      },
      {
        header: 'Centre #',
        accessor: 'id',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Entries',
        accessor: 'entryCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Marks',
        accessor: 'completeCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Absent',
        accessor: 'absentCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Missing',
        accessor: 'missingCount',
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Holds',
        accessor: 'hasHeldLearners',
        renderCell: (row: CentreStats) => (
          <>
            {row.hasHeldLearners && <LabelWithIndicator label="Learners withheld" />}
            {!row.hasHeldLearners && (
              <Typography variant="body1" color="textSecondary">
                Not withheld
              </Typography>
            )}
          </>
        ),
      },
      {
        header: 'Generated report',
        accessor: 'generated',
        sortFunction: (row: CentreStats) => sortGeneratedReports(row),
        renderCell: (row: CentreStats) => {
          if (row.latestGenerated || row.latestGeneratedError)
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                data-testid={`generated-report-${row.id}-box`}
              >
                <CheckpointTooltip
                  title={
                    row.latestGenerated ? (
                      <RunTooltipContent
                        generationRunInfo={row.latestGenerated?.generatedRunInfo}
                      />
                    ) : (
                      ''
                    )
                  }
                  arrow
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    data-testid="icons-box"
                  >
                    {row.latestGenerated && row.latestGenerated.pdfReportInfo?.s3Key !== undefined && (
                      <IconButton
                        onClick={() =>
                          showItem(
                            row.id,
                            row.latestGenerated?.pdfReportInfo?.s3Key || '',
                            row.latestGenerated?.pdfReportInfo?.versionId
                          )
                        }
                        data-testid={`generated-pdf-${row.id}`}
                      >
                        <IconPdf />
                      </IconButton>
                    )}
                    {row.latestGenerated &&
                      row.latestGenerated.xlsxReportInfo?.s3Key !== undefined && (
                        <DownloadS3FileButton
                          s3ObjectVerion={row.latestGenerated?.xlsxReportInfo.versionId}
                          s3key={row.latestGenerated?.xlsxReportInfo?.s3Key}
                          downloadFileName={`${reportFilePrefix(row.id)}.xlsx`}
                        >
                          <IconXls />
                        </DownloadS3FileButton>
                      )}
                    {row.latestGenerated && row.latestGenerated.soaReportInfo?.s3Key !== undefined && (
                      <IconButton
                        onClick={() =>
                          showItem(
                            row.id,
                            row.latestGenerated?.soaReportInfo?.s3Key || '',
                            row.latestGenerated?.soaReportInfo?.versionId
                          )
                        }
                        data-testid={`generated-soa-${row.id}`}
                      >
                        <IconCertificate />
                      </IconButton>
                    )}
                  </Box>
                </CheckpointTooltip>
                {row.latestGeneratedError && row.latestGeneratedError.generatedRunInfo.error && (
                  <WarningIcon generationRunInfo={row.latestGeneratedError.generatedRunInfo} />
                )}
                {/*
                INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)

                {row.dataUpdates && row.dataUpdates.totalCount && !row.latestGeneratedError && (
                  <WarningIcon dataUpdates={row.dataUpdates} />
                )} */}
              </Box>
            );
          return 'No data';
        },
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Issued report',
        accessor: 'issued',
        renderCell: (row: CentreStats) => {
          if (row.latestIssued || row.latestIssuedError)
            return (
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <CheckpointTooltip
                  title={
                    row.latestIssued && row.latestIssued.issuedRunInfo ? (
                      <RunTooltipContent
                        generationRunInfo={row.latestIssued?.generatedRunInfo}
                        issueRunInfo={row.latestIssued?.issuedRunInfo}
                      />
                    ) : (
                      ''
                    )
                  }
                  arrow
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    data-testid="icons-box"
                  >
                    {row.latestIssued && row.latestIssued.pdfReportInfo?.s3Key !== undefined && (
                      <IconButton
                        onClick={() =>
                          showItem(
                            row.id,
                            row.latestIssued?.pdfReportInfo?.s3Key || '',
                            row.latestIssued?.pdfReportInfo?.versionId
                          )
                        }
                        data-testid={`issued-pdf-${row.id}`}
                      >
                        <IconPdf />
                      </IconButton>
                    )}
                    {row.latestIssued && row.latestIssued.xlsxReportInfo?.s3Key !== undefined && (
                      <DownloadS3FileButton
                        s3ObjectVerion={row.latestIssued?.xlsxReportInfo.versionId}
                        s3key={row.latestIssued?.xlsxReportInfo?.s3Key}
                        downloadFileName={`${reportFilePrefix(row.id)}.xlsx`}
                      >
                        <IconXls />
                      </DownloadS3FileButton>
                    )}
                  </Box>
                </CheckpointTooltip>
                {row.latestIssuedError &&
                  row.latestIssuedError.issuedRunInfo &&
                  row.latestIssuedError.issuedRunInfo.error && (
                    <WarningIcon issueRunInfo={row.latestIssuedError.issuedRunInfo} />
                  )}
                {row.latestIssued &&
                  row.latestIssued?.generatedRunInfo?.executionId !==
                    row.latestGenerated?.generatedRunInfo.executionId && (
                    <CheckpointTooltip title="Issued Report is older than latest generated report">
                      <WarningRoundedIcon fontSize="small" htmlColor="#FCC652" />
                    </CheckpointTooltip>
                  )}
              </Box>
            );
          return 'No data';
        },
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Sent to Print',
        accessor: 'soa',
        renderCell: (row: CentreStats) => {
          if (row.latestSoa || row.latestSoaError)
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                data-testid={`issued-soa-${row.id}-box`}
              >
                <CheckpointTooltip
                  title={
                    row.latestSoa && row.latestSoa.issuedRunInfo ? (
                      <RunTooltipContent
                        isPrint
                        generationRunInfo={row.latestSoa?.generatedRunInfo}
                        issueRunInfo={row.latestSoa?.issuedRunInfo}
                      />
                    ) : (
                      ''
                    )
                  }
                  arrow
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    data-testid="icons-box"
                  >
                    {row.latestSoa && row.latestSoa.soaReportInfo?.s3Key !== undefined && (
                      <IconButton
                        onClick={() =>
                          showItem(
                            row.id,
                            row.latestSoa?.soaReportInfo?.s3Key || '',
                            row.latestSoa?.soaReportInfo?.versionId
                          )
                        }
                        data-testid={`issued-soa-${row.id}`}
                      >
                        <IconCertificate />
                      </IconButton>
                    )}
                  </Box>
                </CheckpointTooltip>
                {row.latestSoaError &&
                  row.latestSoaError.issuedRunInfo &&
                  row.latestSoaError.issuedRunInfo.error && (
                    <WarningIcon issueRunInfo={row.latestSoaError.issuedRunInfo} />
                  )}
                {row.latestSoa &&
                  row.latestSoa?.generatedRunInfo.executionId !==
                    row.latestGenerated?.generatedRunInfo.executionId && (
                    <CheckpointTooltip
                      data-testid={`issued-soa-${row.id}-outdate-warning`}
                      title="Printed Report is older than latest generated report"
                    >
                      <WarningRoundedIcon fontSize="small" htmlColor="#FCC652" />
                    </CheckpointTooltip>
                  )}
              </Box>
            );
          return 'No data';
        },
        cellProps: {
          align: 'center' as const,
        },
      },
      {
        header: 'Actions',
        accessor: 'actions',
        renderCell: (row: CentreStats) => (
          <MoreActionMenu
            disableGenerateReport={reportGeneratorRunning || !gradingAvailable || !releaseDate}
            disableIssueReport={
              !!reportIssuerRunning ||
              !row.latestGenerated ||
              !releaseDate ||
              row.latestIssued?.pdfReportInfo?.versionId ===
                row.latestGenerated?.pdfReportInfo?.versionId
            }
            disableSoaPrint={!!soaPrintRunning || !releaseDate || !row.latestGenerated}
            onGenerateReport={() => {
              confirmOneCentreModal(row.id);
            }}
            onHoldOrRelease={() => {
              confirmHoldOrReleaseModal(row.id);
            }}
            onIssueReport={() => {
              confirmIssueReportModal(row.id);
            }}
            onSoaPrint={() => {
              confirmSoaPrintModal(row.id);
            }}
          />
        ),
      },
    ],
    [reportGeneratorRunning, reportIssuerRunning, gradingAvailable, releaseDate]
  );

  const notFound = useMemo(
    () =>
      id &&
      !retrievingSessions &&
      sessions.length > 0 &&
      sessions.filter((x) => x.id === parseInt(id, 10)).length === 0,
    [sessions, retrievingSessions, id]
  );

  const qual = useMemo(() => getQualification(parseInt(qid, 10)), [qid]);
  if (notFound || !qual) {
    return <NotFound />;
  }

  if (retrievingPageDependencies) {
    return <PageLoader />;
  }

  if (pageDependeciesError) {
    return <Error />;
  }

  return (
    <>
      <SessionExpired
        errors={[
          errorRetrievingSessions,
          errorRetrievingCentreStats,
          errorRetrievingGradingData,
          errorRetrievingGradingData,
          errorRetrievingReleaseDate,
          errorSettingReleaseDate,
          errorTriggeringGenerateReport,
          errorTriggeringIssueReport,
          errorTriggeringSoaPrint,
          generatePollError,
          errorQCPoll,
          issuePollError,
          soaPollError,
        ]}
      />
      <Box py={1.5} borderBottom={`4px solid ${qual.color}`}>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center">
            <Box>
              <Typography variant="h4" gutterBottom>
                Centres
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" gutter="sm">
                <Link component={RouterLink} to={`/session/${id}`}>
                  <span>{currentSession}</span>
                </Link>
                <Typography>{qual.name}</Typography>
              </Breadcrumbs>
            </Box>
            <Box ml="auto" display="flex">
              <Box mr={2}>
                <Box display="flex">
                  <DatePicker
                    label="Results release date"
                    onChange={handleReleaseDateChange}
                    minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                    disabled={dateInputDisabled}
                    date={releaseDate}
                  />
                  <Box mt={-0.5} ml={0.5}>
                    <IconButton onClick={toggleDateInput} data-testid="edit-date">
                      <CreateIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <Box display="flex" flexDirection="column" justifyContent="flex-end">
                    {errorSettingReleaseDate && (
                      <div className={classes.warning}>
                        <WarningMessage isError message="Could not set release date" />
                      </div>
                    )}
                    {errorRetrievingReleaseDate && (
                      <div className={classes.warning}>
                        <WarningMessage isError message="Could not retrieve release date" />
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box display="inline-block" mr={2}>
                {reportGeneratorRunning && (
                  <ProgressBar
                    errorCount={latestRelevantGenerationStatus?.errorCount || 0}
                    increment={
                      (latestRelevantGenerationStatus?.centreProgress || 0) +
                      (latestRelevantGenerationStatus?.errorCount || 0)
                    }
                    label="Generating reports..."
                    subLabel="generated"
                    total={latestRelevantGenerationStatus?.centreTotal}
                  />
                )}
                {!reportGeneratorRunning && (
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleClickOpen}
                      data-testid="buttonGenerateReport"
                      disabled={!gradingAvailable || !releaseDate}
                    >
                      Generate <Hidden mdDown>Series Reports</Hidden>
                    </Button>
                    {lastReportGeneratorStatus && (
                      <RunInfoLabel status={lastReportGeneratorStatus} />
                    )}
                  </Box>
                )}
                {errorRetrievingGradingData && (
                  <WarningMessage message="Error retrieving grading data" />
                )}
                {!gradingAvailable && !retrievingGradingData && !errorRetrievingGradingData && (
                  <WarningMessage message="Grading Data not yet available" />
                )}
                {!releaseDate && !retrievingReleaseDate && !errorRetrievingReleaseDate && (
                  <WarningMessage message="Release date is not set" />
                )}
              </Box>
              <Box display="inline-block" mr={2}>
                {reportIssuerRunning && (
                  <ProgressBar
                    increment={
                      (latestRelevantIssuerStatus?.centreProgress || 0) +
                      (latestRelevantIssuerStatus?.errorCount || 0)
                    }
                    label="Issuing reports..."
                    subLabel="issued"
                    total={latestRelevantIssuerStatus?.centreTotal}
                  />
                )}
                {!reportIssuerRunning && (
                  <Box>
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => {
                        setForceIssue(false);
                        setIssueReportsConfirmation(true);
                      }}
                      data-testid="buttonIssueReports"
                      disabled={!lastReportGeneratorStatus || !releaseDate}
                    >
                      Issue <Hidden mdDown>Series Reports</Hidden>
                    </Button>
                    {lastReportIssuerStatus && (
                      <RunInfoLabel
                        status={{
                          ...lastReportIssuerStatus,
                          input: {
                            centres: lastReportIssuerStatus?.input?.centreId
                              ? [lastReportIssuerStatus.input.centreId]
                              : undefined,
                          },
                        }}
                      />
                    )}
                  </Box>
                )}
              </Box>
              <Box display="inline-block">
                {soaPrintRunning && (
                  <ProgressBar
                    increment={
                      (latestRelevantSoaPrintStatus?.centreProgress || 0) +
                      (latestRelevantSoaPrintStatus?.errorCount || 0)
                    }
                    label="Sending SoA to print queue..."
                    subLabel="sent"
                    total={latestRelevantSoaPrintStatus?.centreTotal}
                  />
                )}
                {!soaPrintRunning && (
                  <Box>
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => {
                        setForceIssue(false);
                        setSoasPrintConfirmation(true);
                      }}
                      data-testid="buttonPrintSoas"
                      disabled={!lastReportGeneratorStatus || !releaseDate}
                    >
                      Print <Hidden mdDown>SoAs</Hidden>
                    </Button>
                    {lastSoaPrintStatus && (
                      <RunInfoLabel
                        status={{
                          ...lastSoaPrintStatus,
                          input: {
                            centres: lastSoaPrintStatus?.input?.centreId
                              ? [lastSoaPrintStatus.input.centreId]
                              : undefined,
                          },
                        }}
                      />
                    )}
                  </Box>
                )}
              </Box>
              <DialogWindow
                description="You are about to generate reports for all relevant centres in this series
                      within this qualification. This process is time consuming, the reports will
                      appear one by one once they are generated."
                onCloseDialog={handleClose}
                openDialog={open}
                title="Generate Series Reports"
                ConfirmButtonOnClick={async () => {
                  startGenerateReport(
                    filter === 'inError' && generateFailedOnly && filtered
                      ? filtered.map((x) => x.id)
                      : []
                  );
                  if (!errorTriggeringGenerateReport) {
                    handleClose();
                  }
                }}
                data-testid="DialogConfirmAllCentres"
                buttonDataTestId={generateReportResponse?.executionArn}
                fixedWidth
              >
                <>
                  {filter === 'inError' &&
                    centreSummaryStats &&
                    centreSummaryStats.inError.centreCount > 0 && (
                      <Box my={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              className={classes.switch}
                              onChange={() => setGenerateFailedOnly(!generateFailedOnly)}
                              checked={generateFailedOnly}
                            />
                          }
                          label="Re run failed only"
                          className={classes.switchLabel}
                        />
                      </Box>
                    )}
                  {errorTriggeringGenerateReport !== null && (
                    <ErrorMessage message="Report generation failed. Please try again. If this problem persists, please contact BTS helpdesk" />
                  )}
                </>
              </DialogWindow>
              <DialogWindow
                description="You are about to issue reports for all relevant centres in this series
                      within this qualification. This process is time consuming, the reports will
                      appear one by one once they are issued."
                onCloseDialog={() => setIssueReportsConfirmation(false)}
                openDialog={isIssueReportsOpen}
                title="Issue Reports"
                ConfirmButtonOnClick={async () => {
                  setIssueReportsConfirmation(false);
                  startIssueReport('', forceIssue);
                }}
                data-testid="DialogConfirmIssueAllCentres"
                fixedWidth
              >
                <Box my={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        className={classes.switch}
                        onChange={() => setForceIssue(!forceIssue)}
                        checked={forceIssue}
                      />
                    }
                    label="Include incomplete and held centres"
                    className={classes.switchLabel}
                  />
                </Box>
              </DialogWindow>
              <DialogWindow
                description="You are about to send statements of achievements to print queue for all relevant centres in this series
                      within this qualification. This process is time consuming, the statements of achievements will
                      appear one by one once they are sent."
                onCloseDialog={() => setSoasPrintConfirmation(false)}
                openDialog={isPrintSoasConfirmationOpen}
                title="Send SoAs to print queue"
                ConfirmButtonOnClick={async () => {
                  setSoasPrintConfirmation(false);
                  startSoaPrint('', forceIssue);
                }}
                data-testid="DialogConfirmSoaPrintAllCentres"
                fixedWidth
              >
                <Box my={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={forceIssue}
                        color="primary"
                        className={classes.switch}
                        onChange={() => setForceIssue(!forceIssue)}
                      />
                    }
                    label="Include incomplete and held centres"
                    className={classes.switchLabel}
                  />
                </Box>
              </DialogWindow>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        <Container maxWidth="lg">
          {retrievingCentreStats && <PageLoader />}
          {!retrievingCentreStats && errorRetrievingCentreStats && <Error />}
          {!retrievingCentreStats && !errorRetrievingCentreStats && (
            <>
              {filtered !== undefined && totalCount !== undefined && totalCount > 0 && (
                <Box
                  mt="1rem"
                  mb="1rem"
                  display="flex"
                  flexWrap="wrap-reverse"
                  justifyContent="space-between"
                >
                  <Tabs
                    value={filter || 0}
                    onChange={(
                      _event: React.ChangeEvent<Record<string, unknown>>,
                      newValue: Filter
                    ) => {
                      history.push(
                        newValue
                          ? `/session/${id}/qualification/${qid}?filter=${newValue}`
                          : `/session/${id}/qualification/${qid}`
                      );
                    }}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    data-testid="filter-tabs"
                  >
                    {totalCount && totalEntryCount && (
                      <FilterTab
                        value={0}
                        label={
                          <FilterTabLabel
                            label={getFilterLabel()}
                            primaryCount={totalCount}
                            secondaryCount={totalEntryCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.complete && (
                      <FilterTab
                        value="complete"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('complete')}
                            primaryCount={centreSummaryStats.complete.centreCount}
                            secondaryCount={centreSummaryStats.complete.entryCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.incomplete && (
                      <FilterTab
                        value="incomplete"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('incomplete')}
                            primaryCount={centreSummaryStats.incomplete.centreCount}
                            secondaryCount={centreSummaryStats.incomplete.entryCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.absent && (
                      <FilterTab
                        value="absent"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('absent')}
                            primaryCount={centreSummaryStats.absent.centreCount}
                            secondaryCount={centreSummaryStats.absent.entryCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.missing && (
                      <FilterTab
                        value="missing"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('missing')}
                            primaryCount={centreSummaryStats.missing.centreCount}
                            secondaryCount={centreSummaryStats.missing.entryCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.held && (
                      <FilterTab
                        value="held"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('held')}
                            primaryCount={centreSummaryStats.held.centreCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.inError && (
                      <FilterTab
                        value="inError"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('inError')}
                            primaryCount={centreSummaryStats.inError.centreCount}
                          />
                        }
                      />
                    )}
                    {centreSummaryStats && centreSummaryStats.outdated && (
                      <FilterTab
                        value="outdated"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('outdated')}
                            primaryCount={centreSummaryStats.outdated.centreCount}
                          />
                        }
                      />
                    )}
                    {/* 
                    INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)
                    
                    {centreSummaryStats && centreSummaryStats.withUpdates && (
                      <FilterTab
                        value="withUpdates"
                        label={
                          <FilterTabLabel
                            label={getFilterLabel('withUpdates')}
                            primaryCount={centreSummaryStats.withUpdates.centreCount}
                            secondaryCount={centreSummaryStats.withUpdates.entryCount}
                            secondaryCountType="updates"
                          />
                        }
                      />
                    )} */}
                  </Tabs>

                  <Box alignSelf="flex-end" marginLeft="auto">
                    <Box display="flex" alignItems="center">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={downloadReportHandleClickOpen}
                        data-testid="buttonGenerateQCReport"
                        disabled={buttonDisabled}
                      >
                        Generate QC report
                      </Button>
                      {lastQCReportStatus?.status === 'COMPLETE' && !buttonDisabled && (
                        <Box>
                          <DownloadS3FileButton
                            s3key={`${id}/${qid}/${id}-${qid}.xlsx`}
                            downloadFileName={`${id}-${qid}.xlsx`}
                            downloadReportBucket
                          >
                            <IconXls />
                          </DownloadS3FileButton>
                        </Box>
                      )}
                    </Box>
                    <GeneratedInfoLabel
                      reportDownloadInfo={lastQCReportStatus}
                      runningFunction={buttonDisabled}
                    />
                  </Box>
                </Box>
              )}
              {filtered !== undefined && filtered.length === 0 && (
                <NoResultsView
                  view="no-results"
                  title={filter ? `No ${filter} entries found` : 'No entries found'}
                  message={
                    filter
                      ? 'No entries found that match the filter'
                      : 'We could not find any entries for this series and qualification'
                  }
                />
              )}
              {filtered !== undefined && filtered.length > 0 && (
                <>
                  <Box display="flex" my="1rem">
                    <FilterCheckbox
                      boxLabel="Filter centres by:"
                      singleFilterLabel="E prefix"
                      onChange={handleSingleFilter}
                      data-testid="single-filter"
                      checked={e2lFilterChecked}
                    />
                    <Box ml="auto">
                      <SearchInput
                        value={searchQuery}
                        placeholder="Search for a centre name or number"
                        onChange={handleSearch}
                        onClear={() => handleSearch(null)}
                      />
                    </Box>
                  </Box>
                  {(debouncedQuery || e2lFilterChecked) && tableData?.length === 0 && (
                    <NoResultsView
                      title={`No results found for ‘${debouncedQuery || 'E prefix filter'}’`}
                      message={
                        debouncedQuery
                          ? `We couldn’t find a match for ‘${debouncedQuery}’.
                  Please try another search.`
                          : ''
                      }
                      view="no-search"
                    >
                      <>
                        {debouncedQuery && (
                          <Button
                            color="primary"
                            size="small"
                            onClick={() => {
                              history.push(`/session/${id}/qualification/${qid}`);
                              handleSearch(null);
                            }}
                          >
                            Reset the search
                          </Button>
                        )}
                      </>
                    </NoResultsView>
                  )}
                  {!((debouncedQuery || e2lFilterChecked) && tableData?.length === 0) && (
                    <>
                      <Table<CentreStats>
                        title={filterLabel}
                        columns={headerColumns}
                        getRowKey={(item: CentreStats) => item.id}
                        data={tableData}
                        rowsPerPage={10}
                        data-testid="marks-table"
                      />
                      {selectedKey && (
                        <ReportViewerModal
                          downloadFileName={selectedKey.fileName}
                          reportKey={selectedKey.key}
                          reportVersion={selectedKey.version}
                          isOpen={showViewer}
                          toggle={toggle}
                        />
                      )}
                      {selectedCentre && (
                        <DialogWindow
                          description="Please confirm you wish to run centre reports"
                          onCloseDialog={handleCloseOneCentre}
                          openDialog={openConfirmOneCentreModal}
                          title="Confirm"
                          ConfirmButtonOnClick={async () => {
                            startGenerateReport([selectedCentre]);
                            if (!errorTriggeringGenerateReport) {
                              handleCloseOneCentre();
                            }
                          }}
                          fixedWidth
                          data-testid="DialogConfirmOneCentre"
                          buttonDataTestId={generateReportResponse?.executionArn}
                        >
                          <>
                            {errorTriggeringGenerateReport && (
                              <ErrorMessage message="Report generation failed. Please try again. If this problem persists, please contact BTS helpdesk" />
                            )}
                          </>
                        </DialogWindow>
                      )}
                      {selectedCentre && (
                        <DialogWindow
                          description={`Please confirm which subjects to be on hold or released for centre ${selectedCentre}:`}
                          onCloseDialog={handleCloseHoldOrRelease}
                          openDialog={holdOrReleaseModal}
                          title="Holds update"
                          ConfirmButtonOnClick={async () => {
                            await confirmHoldCompleteModal();
                          }}
                          data-testid="DialogConfirmHold"
                          disableButton={!Object.keys(holdOrReleaseUpdates).length}
                          buttonDataTestId="confirmHoldButton"
                        >
                          {holdStatus && (
                            <HoldOptions
                              assessmentsHold={holdStatus.map((assessment) => ({
                                assessmentCode: assessment.assessmentCode,
                                assessmentName: assessment.assessmentName,
                                held: assessment.isWholeSubjectOnHold
                                  ? 'All'
                                  : assessment.heldLearners?.length,
                              }))}
                              onClick={(option: ChangeEvent<HTMLInputElement>) => {
                                const input: string[] = option.target.value.split('-');
                                setHoldOrReleaseUpdates({
                                  ...holdOrReleaseUpdates,
                                  [input[0]]: input[1],
                                });
                              }}
                              selectedValues={holdOrReleaseUpdates}
                            />
                          )}
                          {errorRetrievingHoldStatus && <Error />}
                          {retrievingHoldStatus && <Loader />}
                        </DialogWindow>
                      )}
                      {selectedCentre && (
                        <DialogWindow
                          description={
                            setHoldStatusResponse
                              ? `Holds have been updated for centre ${selectedCentre}`
                              : `Updating holds for centre ${selectedCentre}...`
                          }
                          onCloseDialog={handleCloseHoldComplete}
                          openDialog={holdCompleteModal}
                          title="Holds update"
                          ConfirmButtonOnClick={() => {
                            handleCloseHoldComplete();
                          }}
                          data-testid="DialogConfirmHoldComplete"
                          disableButton={!setHoldStatusResponse}
                          buttonDataTestId="confirmHoldCompleteButton"
                        >
                          {errorSettingHoldStatus && <Error />}
                          {retrievingSetHoldStatusResponse && <Loader />}
                        </DialogWindow>
                      )}
                    </>
                  )}
                  {selectedCentre && (
                    <DialogWindow
                      description={issueReportConfirmationMsg}
                      onCloseDialog={handleCloseIssueReport}
                      openDialog={issueReportModal}
                      title="Confirm"
                      ConfirmButtonOnClick={async () => {
                        handleCloseIssueReport();
                        startIssueReport(selectedCentre, true);
                      }}
                      data-testid="DialogConfirmIssueOneCentre"
                      buttonDataTestId="confirmIssueReportButton"
                    />
                  )}
                  {selectedCentre && (
                    <DialogWindow
                      description={issueReportConfirmationMsg}
                      onCloseDialog={handleCloseSoaPrint}
                      openDialog={soaPrintModal}
                      title="Confirm"
                      ConfirmButtonOnClick={async () => {
                        handleCloseSoaPrint();
                        startSoaPrint(selectedCentre, true);
                      }}
                      data-testid="DialogConfirmPrintOneSoa"
                      buttonDataTestId="confirmSoaPrintButton"
                    />
                  )}
                  <DialogWindow
                    description="This process can take several minutes. The latest report will appear when ready"
                    onCloseDialog={downloadReportHandleClose}
                    openDialog={downloadReportOpen}
                    title="Confirm"
                    ConfirmButtonOnClick={async () => {
                      startGenerateDownloadReport();
                      setButtonDisabled(true);
                      if (!errorTriggeringGenerateDownloadReport) {
                        downloadReportHandleClose();
                      }
                    }}
                    fixedWidth
                    data-testid="DialogDownloadReport"
                    buttonDataTestId={generateDownloadReportResponse?.split(' ')[12]}
                  >
                    {errorTriggeringGenerateDownloadReport ? (
                      <ErrorMessage message="The process failed. If this problem persists, please contact BTS helpdesk" />
                    ) : (
                      <></>
                    )}
                  </DialogWindow>
                </>
              )}
            </>
          )}
        </Container>
      </Box>
    </>
  );
};
