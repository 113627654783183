import React, { useMemo, useEffect } from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { SessionQualStatsRow } from '../components/SessionQualStatsRow';
import Select from '../components/Select/Select';
import { PageLoader } from '../components/PageLoader';
import { Error, NotFound } from '../components/NoResultsView/NoResultsView';
import { useSessions } from '../utils/useSessions';
import { useSessionStats } from '../utils/useSeriesStats';
import SessionExpired from '../components/SessionExpired/SessionExpired';
// INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)
//
// import { useQualificationsUpdates } from '../utils/useQualificationsUpdates';

export interface SessionPath {
  id: string;
}

export const SessionOverviewPage: React.FC = (): JSX.Element => {
  const { id } = useParams<SessionPath>();
  const history = useHistory();
  const { sessions, mostRecentSeries, retrievingSessions, errorRetrievingSessions } = useSessions();

  const series = useMemo(
    () => sessions.map((item) => ({ value: item.id, label: item.displayName })),
    [sessions]
  );

  const setSeries = (sessionId: number): void => history.push(`/session/${sessionId}`);

  useEffect(() => {
    if (!id && mostRecentSeries) {
      setSeries(mostRecentSeries);
    }
  }, [id, mostRecentSeries]);

  const { sessionStats, retrievingSessionStats, errorRetrievingSessionStats } = useSessionStats(id);

  // INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)
  //
  // const qualificationIds = useMemo(
  //   () => sessionStats.map((s) => s.qualificationId),

  //   [sessionStats]
  // );

  // const {
  //   retrievingSessionUpdates,
  //   sessionUpdates,
  //   errorRetrievingSessionUpdates,
  // } = useQualificationsUpdates(parseInt(id, 10), qualificationIds);

  const notFound = useMemo(
    () =>
      id &&
      !retrievingSessions &&
      sessions.length > 0 &&
      sessions.filter((x) => x.id === parseInt(id, 10)).length === 0,
    [sessions, retrievingSessions, id]
  );

  // INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)
  // Below are commented out variables that relate to with updates feature
  const retrievingPageDependencies = useMemo(
    () => retrievingSessions || retrievingSessionStats, // || retrievingSessionUpdates,
    [retrievingSessions, retrievingSessionStats] // , retrievingSessionUpdates]
  );
  const pageDependeciesError = useMemo(
    () => errorRetrievingSessions || errorRetrievingSessionStats, // || errorRetrievingSessionUpdates,
    [errorRetrievingSessions, errorRetrievingSessionStats]
  );

  if (notFound) {
    return <NotFound />;
  }
  return (
    <>
      <SessionExpired errors={[errorRetrievingSessions, errorRetrievingSessionStats]} />
      <Box py={1.5}>
        <Container maxWidth="lg">
          {!retrievingSessions && !errorRetrievingSessions && (
            <>
              <Typography variant="h3" gutterBottom>
                Recent series data
              </Typography>
              <Box pb={2}>
                <Select
                  label="Series:"
                  options={series}
                  onChange={(e) => setSeries(e.target.value as number)}
                  value={id}
                  data-testid="series-select"
                />
              </Box>
            </>
          )}
          {retrievingPageDependencies && <PageLoader />}
          {pageDependeciesError && <Error />}
          {!retrievingPageDependencies && !pageDependeciesError && (
            <Box mx="3.5rem">
              {sessionStats.length &&
                sessionStats.map((x) => (
                  <SessionQualStatsRow
                    key={x.qualificationId}
                    sessionId={parseInt(id, 10)}
                    stats={x}
                    // INFO: Commented out non-production ready feature (WITH-UPDATES-FEATURE)
                    updates={null} // (sessionUpdates && sessionUpdates[x.qualificationId]) || null}
                  />
                ))}
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};
